import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { ChunkStore } from '@/store/modules/ChunkStore'
import { IndexStore } from '@/store/modules/IndexStore'
import { MetaStore } from '@/store/modules/MetaStore'
import { GraphStore } from '@/store/modules/GraphStore'
import { UIStore } from '@/store/modules/UIStore'
import { RootState } from '@/types/StoreTypes'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  modules: {
    chunkStore: ChunkStore,
    indexStore: IndexStore,
    metaStore: MetaStore,
    graphStore: GraphStore,
    uiStore: UIStore
  }
}

export default new Vuex.Store<any>(store)
