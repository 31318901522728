// Vue
import Vue from 'vue'
import store from '@/store'

// Pixi
import * as PIXI from 'pixi.js'
import { Viewport } from 'pixi-viewport'

// Config
import Config from './Config'
import { BinInfo, ChunkData, ReadTrack, EditObject } from '@/types/Types'

// Utils
import TooltipUtils from '@/utils/TooltipUtils'
import GeneralUtils from '@/utils/GeneralUtils'
import SortingUtils from '../utils/SortingUtils'

// Services
import LocalFileProvider from '@/services/LocalFileProvider'

// Graph modules
import SortingTools from './modules/SortingTools'
import Loader from './modules/Loader'

// Xtras
import chroma, { Color } from 'chroma-js'

// -------------------------------------------------

// Font
const bitmapFont: PIXI.BitmapFont = PIXI.BitmapFont.from('MonoBitmapFont', Config.getSequenceBitmapTextStyle(), { chars: PIXI.BitmapFont.ALPHA })

// Colors
const fillColor: number = Config.fillColor
const invColor: number = Config.invColor
const duplColor: number = Config.duplColor
const invDuplColor: number = Config.invDuplColor
const emptyColor: number = Config.emptyColor
const geneColor: number = Config.intronColor
const exonColor: number = Config.exonColor
const spacerColor: number = Config.spacerColor
const backgroundColor: number = Config.backgroundColor
const nextArrowColor: number = Config.nextArrowColor
const invColorPaletteScale: chroma.Scale = chroma.scale([GeneralUtils.numToHex(fillColor), GeneralUtils.numToHex(invColor)])
const fillColorPaletteScale: chroma.Scale = chroma.scale([chroma(emptyColor).darken(0.5), GeneralUtils.numToHex(fillColor)])
const duplColorPaletteArray: Array<string> = chroma.scale([GeneralUtils.numToHex(duplColor), GeneralUtils.numToHex(spacerColor)]).colors(8)
const linkColorPalette: chroma.Scale = chroma.scale('Spectral')

// -------------------------------------------------

// App
let app: PIXI.Application

// Ticker
let ticker: PIXI.Ticker

// Containers
let graph: HTMLCanvasElement
let matrixViewport: Viewport
let sequenceContainer: PIXI.Container
let metaContainer: PIXI.Container

// Sizes
let cellHeight: number
let cellWidth: number
let cellMargin: number
let cellWidthMargin: number
let topMargin: number
let bottomMargin: number
let linkHeights: Array<boolean>

// Modules
let sortingTools: SortingTools
let loader: Loader

// Misc
let stickTooltip: boolean
let drawnChunks: Set<number> = new Set<number>()
let highlightColumnRect: PIXI.Graphics

const Graph = {
  init () {
    // Init graph container
    graph = document.getElementById('graph') as HTMLCanvasElement

    // Init pixi app
    app = new PIXI.Application<HTMLCanvasElement>({
      antialias: true,
      backgroundColor,
      width: graph.offsetWidth,
      height: graph.offsetHeight,
      resizeTo: graph
    })
    graph.appendChild(app.view as HTMLCanvasElement)

    // Init loader
    loader = new Loader(app.view.width, app.view.height)

    // Init ticker
    ticker = PIXI.Ticker.shared
    ticker.maxFPS = 60

    ticker.add(() => {
      loader.update()
    })

    // Init matrix viewport
    this.initMatrixViewport()

    // Init stage
    this.setStage()
  },

  initMatrixViewport () {
    let screenWidth
    let screenHeight
    if (!graph) {
      screenWidth = window.innerWidth
      screenHeight = window.innerHeight
    } else {
      screenWidth = graph.getBoundingClientRect().width
      screenHeight = graph.getBoundingClientRect().height
    }

    // Init matrix viewport
    matrixViewport = new Viewport({
      screenWidth,
      screenHeight,
      worldWidth: 0,
      worldHeight: 0,
      disableOnContextMenu: true,
      events: app.renderer.events
    })

    // Activate plugins
    matrixViewport
      .drag()
      .pinch()
      .wheel()
      .decelerate({ minSpeed: 0.2, bounce: 0.9 })
      .clampZoom({ minScale: 0.5, maxScale: 2.5 })

    // Load neighboring chunk(s) when user hits bounce box
    matrixViewport.on('bounce-x-start', (e) => {
      // Prevent loading if we are already loading
      if (store.state.graphStore.loading) {
        return
      }

      const side = this.checkSide(e.left, e.right)
      if (side !== 'undefined') {
        store.commit('graphStore/setLoading', true)

        const cachedChunkIDs: Array<any> = Object.keys(store.state.chunkStore.cachedChunks)
        let nextChunkID = Math.max(...cachedChunkIDs) + 1

        if (side === 'right') nextChunkID = Math.max(...cachedChunkIDs) + 1
        else if (side === 'left') nextChunkID = Math.min(...cachedChunkIDs) - 1

        if (nextChunkID < 0 || nextChunkID > LocalFileProvider.getLastFileIndex()) {
          // We reached the first or last chunk, so just draw the end spacers
          this.drawEndSpacers()
          store.commit('graphStore/setLoading', false)
          return
        }

        // Keep max nr chunks loaded
        if (cachedChunkIDs.length + 1 > Config.maxNrCachedChunks) {
          this.deleteChunk(side)
        }

        // Reset bounce
        // this.resetBounce()

        const graphTracks = this.getCurrentTracks().graphTracks
        const cachedGraphTracks = graphTracks.join()
        const chunks = [nextChunkID]
        LocalFileProvider.loadTracks(chunks, true).then(() => {
          this.filterPathsByCov().then(() => {
            if (cachedGraphTracks !== graphTracks.join()) {
              const drawAtBin = this.getBinInfoForRawX(matrixViewport.left + store.state.metaStore.neededLeftMargin).binNumber
              this.drawCachedChunks(drawAtBin, store.state.metaStore.isHightlighted, side === 'right' ? 'left' : 'right')
            } else {
              this.drawChunk(nextChunkID)
              if (store.state.metaStore.drawLinks) {
                this.drawConnectedLinks(matrixViewport)
              }
              this.updateBounce()
            }
          }).finally(() => {
            // Align containers after a short delay to prevent containers misalignment in some cases
            // NOTE: still not pretty clear why this is happening so randomly
            setTimeout(() => {
              this.alignContainers()
            }, 100)
            store.commit('graphStore/setLoading', false)
          })
        })
      }
    })

    matrixViewport.on('moved', (e) => {
      // TODO: should be called only one time if visible
      TooltipUtils.hide()
      // Align containers
      this.alignContainers()
    })

    matrixViewport.on('zoomed', (e) => {
      sequenceContainer.scale.x = matrixViewport.scale.x
      sequenceContainer.scale.y = matrixViewport.scale.y
      metaContainer.scale.x = matrixViewport.scale.x
      metaContainer.scale.y = matrixViewport.scale.y

      // Set chunkStore scale (ObservablePoint)
      store.commit('chunkStore/setCurrentZoomLevel', matrixViewport.scale)
      // Set graphStore scale (Number)
      store.commit('graphStore/setScale', matrixViewport.scale.x)
    })

    // Only send 'click data' here. Every other action has to be handled in PView.
    matrixViewport.on('clicked', (e) => {
      if (GeneralUtils.isRightClick(e)) {
        // Show right click menu
        const offset = {
          x: 10,
          y: 260
        }
        const x = e.screen.x + offset.x
        const y = e.screen.y + offset.y

        store.commit('metaStore/setRightClickData', {
          show: true,
          rawCoords: e.world,
          x,
          y,
          isLink: this.checkLink(e.world.x, e.world.y)
        })
      } else {
        // If tooltip is already sticked we remove it on left click
        // TODO: should be moved to PView where all the actions are handled
        if (stickTooltip) {
          stickTooltip = false
          this.hideTooltip()
        }

        // Stick tooltip on left click of tooltip is already displayed on mouse over
        if (store.state.metaStore.tooltipShown) {
          stickTooltip = true
        }

        let cellHasInfo = false
        if (this.getCellInfo(e.world.x, e.world.y) !== '') cellHasInfo = true

        store.commit('metaStore/setLeftClickData', {
          rawCoords: e.world,
          cellHasInfo: cellHasInfo,
          cellHasGene: this.cellHasGene(e.world.x, e.world.y)
        })
      }
    })

    matrixViewport.on('drag-start', (e) => {
      if (stickTooltip) stickTooltip = false
    })

    app.stage.addChild(matrixViewport)
  },

  setStage () {
    // Init sizes
    cellWidth = Config.cellWidth
    cellMargin = Config.cellMargin
    cellWidthMargin = cellWidth + cellMargin
    cellHeight = Config.cellHeight + cellMargin
    topMargin = Config.topMargin
    bottomMargin = Config.bottomMargin
    linkHeights = Array(Config.maxNrLinks).fill(false)

    // Init main containers
    sequenceContainer = new PIXI.Container()
    metaContainer = new PIXI.Container()

    // Add children to stage
    app.stage.addChild(sequenceContainer)
    app.stage.addChild(metaContainer)
    app.stage.addChild(loader)

    // Init tooltip
    // TODO: very dirty way of initializing tooltip, should be initialized in app init
    this.initTooltip()

    // Set scale (used on redraw/drawCachedChunks)
    if (store.state.chunkStore.currentZoomLevel) {
      this.setScale(store.state.chunkStore.currentZoomLevel.x)
      metaContainer.position.y = matrixViewport.position.y
      sequenceContainer.position = matrixViewport.position
    } else {
      store.commit('chunkStore/setCurrentZoomLevel', matrixViewport.scale)
    }
    // Scale retreived from query param (sharing), see applyQuery in PView
    // TODO: we should only use this property to keep track of the scale: remove chunkStore.currentZoomLevel
    if (store.state.graphStore.scale) {
      this.setScale(store.state.graphStore.scale)
    }

    // Handle the case when we redraw after a zoom in/out and highlight is at the center (menu closed)
    store.commit('graphStore/setIsHighlightMenuOpened', false)
  },

  resetStage () {
    // Remove loader
    app.stage.removeChild(loader)
    // Destroy main containers
    sequenceContainer.destroy()
    metaContainer.destroy()
    // Remove all children from matrixViewport
    matrixViewport.removeChildren()
    // Cleanup cache and set stage again
    this.cleanupCache()
    this.setStage()
  },

  // TODO: filter only on store models change
  getCurrentTracks () {
    // List of graphTracks that are not disabled by the user
    const disabledGraphTracks = store.state.uiStore.disabledGraphTracks
    // const graphTracks = [...store.state.chunkStore.graphTracks.keys()].filter((graphTrack) => !disabledGraphTracks.includes(graphTrack))
    const graphTracks = [...store.state.chunkStore.graphTracks.keys()].filter((graphTrack) => !disabledGraphTracks.includes(graphTrack))

    // List of readTracks that are not disabled by the user
    const disabledReadTracks = store.state.uiStore.disabledReadTracks
    const chunk = store.state.chunkStore.currentChunk
    let readTracks: Array<ReadTrack> = []
    if (store.state.metaStore.readsInFiles) {
      readTracks = store.state.chunkStore.readTracks[chunk].filter((readTrack: ReadTrack) => !disabledReadTracks.includes(readTrack.readset_name))
    }

    return {
      graphTracks: graphTracks,
      readTracks: readTracks
    }
  },

  // Check if mouse is not moving then show tooltip after x (time) sec
  initTooltip () {
    const time = 500
    let timeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      // Init timeout
    })
    matrixViewport.on('pointermove', (e) => {
      if (!stickTooltip) {
        this.hideTooltip()
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          const coords = {
            screen: e.data.global,
            world: matrixViewport.toWorld(e.data.global)
          }
          this.showTooltip(coords)
        }, time)
      }
    })
  },

  // Show tooltip
  showTooltip (coords: { screen: PIXI.Point, world: PIXI.Point }) {
    // Don't show tooltip if HighlightMenu is open
    if (!store.state.graphStore.isHighlightMenuOpened) {
      TooltipUtils.show()
      store.commit('metaStore/setTooltipShown', true)
      // Set content (meta or world)
      const relativeX = coords.world.x - matrixViewport.left
      if (relativeX <= store.state.metaStore.neededLeftMargin) {
        TooltipUtils.toggleMetaTooltip(coords, relativeX, graph, sortingTools.tooltipTarget)
      } else {
        TooltipUtils.toggleWorldTooltip(coords, graph)
      }
    }
  },

  // Hide tooltip
  hideTooltip () {
    TooltipUtils.hide()
    store.commit('metaStore/setTooltipShown', false)
  },

  // resetBounce () {
  //   matrixViewport.bounce({
  //     sides: 'none'
  //   })
  // },

  updateBounce () {
    // The viewport must at least have the size of the screen/container,
    // otherwise paths are centered and cannot really be moved around on the canvas
    if (matrixViewport.worldHeight < matrixViewport.screenHeight) {
      matrixViewport.worldHeight = matrixViewport.screenHeight
    }

    let xStart = (store.state.chunkStore.currentFirstColumn - 1) * cellWidthMargin
    let xEnd = (store.state.chunkStore.currentLastColumn + 1) * cellWidthMargin + store.state.metaStore.neededLeftMargin
    if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
      xStart = (store.state.chunkStore.currentFirstBin - 1) * cellWidthMargin
      xEnd = (store.state.chunkStore.currentLastBin + 1) * cellWidthMargin + store.state.metaStore.neededLeftMargin
    }

    matrixViewport.bounce({
      sides: 'all',
      bounceBox: new PIXI.Rectangle(xStart, -(matrixViewport.screenHeight / 2), xEnd, matrixViewport.screenHeight),
      underflow: 'left',
      time: 100
    })
  },

  checkSide (left: number, right: number) {
    let xStart = (store.state.chunkStore.currentFirstColumn - 1) * cellWidthMargin
    if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
      xStart = (store.state.chunkStore.currentFirstBin - 1) * cellWidthMargin
    }
    if (left < xStart) {
      return 'left'
    } else if (right > xStart + matrixViewport.screenWidth) {
      return 'right'
    }
    return 'undefined'
  },

  deleteChunk (side: string) {
    const chunks: Array<any> = Object.keys(store.state.chunkStore.cachedChunks)

    // If there are no chunks, we can't delete any
    if (chunks.length === 0) return false

    while (Object.keys(store.state.chunkStore.cachedChunks).length > Config.maxNrCachedChunks) {
      let chunkNrToDel: any

      if (side === 'right') {
        chunkNrToDel = Math.min(...chunks)
      } else if (side === 'left') {
        chunkNrToDel = Math.max(...chunks)
      }

      const chunkToDel = store.state.chunkStore.cachedChunks[chunkNrToDel]
      for (const p of Object.keys(chunkToDel.paths)) {
        store.commit('metaStore/decrPathsToDraw', { id: p, val: 'cov_bins' in chunkToDel.paths[p] ? chunkToDel.paths[p].cov_bins : chunkToDel.nrBins })
        store.commit('metaStore/decrPathsCurrView', { id: p, val: 'cov_bins' in chunkToDel.paths[p] ? chunkToDel.paths[p].cov_bins : chunkToDel.nrBins })
      }
      store.commit('chunkStore/decrCachedBins', chunkToDel.nrBins)
      store.commit('chunkStore/deleteCachedChunks', chunkNrToDel)

      this.updateCachedChunksCoords()
    }

    return true
  },

  updateCachedChunksCoords () {
    // update the current viewport
    const chunks: Array<any> = Object.keys(store.state.chunkStore.cachedChunks) // Array<number> is not accepted
    const leftChunk = Math.min(...chunks)
    const rightChunk = Math.max(...chunks)
    store.commit('chunkStore/setCurrentFirstBin', store.state.chunkStore.cachedChunks[leftChunk].firstBin)
    store.commit('chunkStore/setCurrentFirstColumn', store.state.chunkStore.cachedChunks[leftChunk].firstCol)
    store.commit('chunkStore/setCurrentLastBin', store.state.chunkStore.cachedChunks[rightChunk].lastBin)
    store.commit('chunkStore/setCurrentLastColumn', store.state.chunkStore.cachedChunks[rightChunk].lastCol)
  },

  filterPathsByCov () {
    return new Promise<void>((resolve) => {
      let maxCov = 0

      for (const path of [...store.state.chunkStore.rawGraphTracks.keys()]) {
        if (store.state.chunkStore.rawGraphTracks.get(path) / store.state.chunkStore.cachedBins < store.state.metaStore.covFraction) {
          store.commit('chunkStore/deleteGraphTrack', path)
        } else if (!(store.state.chunkStore.graphTracks.has(path))) {
          const cov = store.state.chunkStore.rawGraphTracks.get(path)
          store.commit('chunkStore/addGraphTrack', { id: path, val: cov })
        }
        if (store.state.chunkStore.rawGraphTracks.get(path) > maxCov) {
          maxCov = store.state.chunkStore.rawGraphTracks.get(path)
        }
      }
      SortingUtils.sortTracks()

      const after = this.getCurrentTracks().graphTracks.length
      store.commit('chunkStore/setNbTracks', 'Show ' + after + ' paths from ' + [...store.state.chunkStore.rawGraphTracks.keys()].length)

      resolve()
    })
  },

  setScale (scale: number) {
    matrixViewport.scale.x = scale
    matrixViewport.scale.y = scale
    sequenceContainer.scale.x = scale
    sequenceContainer.scale.y = scale
    metaContainer.scale.x = scale
    metaContainer.scale.y = scale
  },

  drawSpacer (x: number) {
    const graphics = new PIXI.Graphics()

    const y = 0
    const width = cellWidth
    const height = (this.getCurrentTracks().graphTracks.length + 2) * (cellHeight) - 1

    graphics.beginFill(spacerColor)

    graphics.drawRect(x, y, width, height)
    graphics.endFill()

    matrixViewport.addChild(graphics)
  },

  highlight (col: number) {
    if (col >= 0) {
      const calculatedx = store.state.metaStore.neededLeftMargin + col * cellWidthMargin
      store.commit('graphStore/setSelectedHighlight', col)
      this.drawHighlighting(calculatedx)
    }
  },

  drawHighlighting (x: number) {
    if (x >= store.state.metaStore.neededLeftMargin) {
      let y = 0
      if (store.state.metaStore.maxLinkHeight > 0) {
        y = -((store.state.metaStore.maxLinkHeight + 2) * (cellHeight) + topMargin)
      }
      const width = cellWidth
      let height = -y + (this.getCurrentTracks().graphTracks.length + 2) * (cellHeight)
      if (store.state.metaStore.readsInFiles) {
        height += (store.state.metaStore.readsetNames.length + Config.blankRowsBeforeReads) * cellHeight
      }

      if (highlightColumnRect) matrixViewport.removeChild(highlightColumnRect)
      highlightColumnRect = new PIXI.Graphics()

      highlightColumnRect.beginFill(0xffff00, 0.8)
      highlightColumnRect.drawRect(x, y, width, height)
      highlightColumnRect.endFill()

      this.addHighlight()
    }
  },

  addHighlight () {
    matrixViewport.addChild(highlightColumnRect)
    store.commit('graphStore/setIsHighlighted', true)
  },

  removeHighlight () {
    matrixViewport.removeChild(highlightColumnRect)
    store.commit('graphStore/setIsHighlighted', false)
    store.commit('graphStore/setSelectedHighlight', null)
  },

  drawEndSpacers () {
    if (LocalFileProvider.getLastFileIndex() in store.state.chunkStore.cachedChunks) {
      if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
        this.drawSpacer(store.state.metaStore.neededLeftMargin + (store.state.chunkStore.currentLastColumn + 2) * cellWidthMargin)
      } else {
        this.drawSpacer(store.state.metaStore.neededLeftMargin + (store.state.chunkStore.currentLastBin + 2) * cellWidthMargin)
      }
    }
    if (0 in store.state.chunkStore.cachedChunks) {
      this.drawSpacer(store.state.metaStore.neededLeftMargin - 2 * cellWidthMargin)
    }
  },

  // Used to shift xcoords on draw/redraw to center the view on the highlight (if active)
  getShiftedX (x: number) {
    x -= ((matrixViewport.screenWidth - (store.state.metaStore.neededLeftMargin * matrixViewport.scale.x)) / 2) / matrixViewport.scale.x
    return x
  },

  async loadAndDrawChunks (startBin = 1, center = false, highlight = false) {
    this.resetStage()

    if (store.state.indexStore.pathMap.size === 0) {
      await LocalFileProvider.checkAndLoadIndexFile(store.state.chunkStore.path)
    }

    if (store.state.metaStore.denseView) {
      cellWidth = Config.denseCellWidth
      cellWidthMargin = Config.denseCellWidth
    }
    if (!store.state.metaStore.drawCellMargin) {
      cellWidthMargin = cellWidth
    }

    // get chunks to the left and right of startBin
    const chunkID = LocalFileProvider.getFileIndexForBinPos(Math.max(0, startBin))
    const lastFileId = LocalFileProvider.getLastFileIndex()
    let chunkIDs = [chunkID]
    let nrChunksToLoadEachSide = store.state.chunkStore.nrChunksToLoad
    if (store.state.chunkStore.binWidth >= 10000) {
      // load more chunks in high bin widths (chunks are smaller and more fit on the screen):
      nrChunksToLoadEachSide *= 2
    }
    for (let i = Math.max(0, chunkID - nrChunksToLoadEachSide); i <= Math.min(chunkID + nrChunksToLoadEachSide, lastFileId); i++) {
      if (LocalFileProvider.getFileName(chunkID) in store.state.chunkStore.cachedChunks) console.log('chunk', chunkID, 'is already cached!')
      if (i !== chunkID) chunkIDs.push(i)
    }

    await LocalFileProvider.loadTracks(chunkIDs)
    // console.log('[loadAndDraw] loaded chunks', Object.keys(store.state.chunkStore.cachedChunks).join())

    // check if we have to load even more chunks to fill the screen
    let cachedChunks: Array<any> = Object.keys(store.state.chunkStore.cachedChunks)
    let leftCol: number
    do {
      const relativePosInFile = startBin - store.state.chunkStore.cachedChunks[chunkID].firstBin
      leftCol = startBin
      let rightCol = store.state.chunkStore.currentLastBin
      if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
        leftCol = +startBin + +store.state.chunkStore.cachedChunks[chunkID].xoffsets[relativePosInFile]
        rightCol = store.state.chunkStore.currentLastColumn
      }
      chunkIDs = []
      if (rightCol - leftCol + 1 < matrixViewport.screenWidth / cellWidthMargin) {
        cachedChunks = Object.keys(store.state.chunkStore.cachedChunks)
        const chunkToLoad = Math.max(...cachedChunks) + 1
        if (chunkToLoad <= lastFileId) {
          chunkIDs.push(chunkToLoad)
          // console.log('[loadAndDraw] load more cached chunks:', chunkToLoad)
          const extend = true
          await LocalFileProvider.loadTracks(chunkIDs, extend)
        } else {
          // we reached the last chunk
          break
        }
      } else {
        break
      }
    } while (true)

    // Adjust max nr loaded chunks, config setting nrChunksToLoadEachSide has higher priority than maxNrCachedChunks
    if (cachedChunks.length > Config.maxNrCachedChunks) {
      Config.maxNrCachedChunks = cachedChunks.length
    }

    await this.filterPathsByCov()

    if (store.state.metaStore.neededLeftMargin === 0) {
      await LocalFileProvider.initMetadataFromFile()
    }

    this.drawMetaContainer(metaContainer)

    // this gets incremented in each drawChunk call below
    // matrixViewport.worldWidth = 0

    let c: any
    for (c of Object.keys(store.state.chunkStore.cachedChunks)) {
      this.drawChunk(c)
    }

    this.drawEndSpacers()

    // restore a previous highlight:
    if (store.state.graphStore.selectedHighlight) {
      this.highlight(store.state.graphStore.selectedHighlight)
    }

    if (startBin !== 1 || highlight) {
      let xcoord = leftCol * cellWidthMargin
      if (highlight && center) {
        // We shift xcoord to center the view on the highlight
        xcoord = this.getShiftedX(xcoord)
      }
      matrixViewport.left = xcoord

      if (highlight) {
        this.highlight(leftCol)
        store.commit('graphStore/setSelectedHighlight', leftCol)
      }
    }

    if (store.state.metaStore.drawLinks) {
      this.drawConnectedLinks(matrixViewport)
    }

    this.updateBounce()
    this.alignContainers()
  },

  drawCachedChunks (startBin = 0, highlightColumn = false, anchorViewport = 'undefined') {
    this.resetStage()
    loader.show()

    drawnChunks = new Set<number>()

    let viewAnchor = matrixViewport.left
    if (anchorViewport === 'right') {
      viewAnchor = matrixViewport.right
    }
    const viewCenter = matrixViewport.center
    const viewTop = matrixViewport.top

    if (store.state.metaStore.denseView) {
      cellWidth = Config.denseCellWidth
      cellWidthMargin = Config.denseCellWidth
    }
    if (!store.state.metaStore.drawCellMargin) {
      cellWidthMargin = cellWidth
    }

    this.drawMetaContainer(metaContainer)

    // This gets incremented in each drawChunk call below
    // matrixViewport.worldWidth = 0

    // Draw chunks
    const promises = []
    let cachedChunk: any // although it's a number, specifying number here doesn't work
    for (cachedChunk in store.state.chunkStore.cachedChunks) {
      promises.push(this.drawChunk(cachedChunk))
    }

    this.drawEndSpacers()

    let xcoord: number
    Promise.allSettled(promises).then(() => {
      this.updateCachedChunksCoords()
      if (store.state.metaStore.drawLinks) {
        this.drawConnectedLinks(matrixViewport)
      }

      if (startBin !== 0) {
        let column: number
        if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
          const chunkIDOfStartBin = LocalFileProvider.getFileIndexForBinPos(startBin)
          const relativePosInFile = startBin - store.state.chunkStore.cachedChunks[chunkIDOfStartBin].firstBin
          column = startBin + parseInt(store.state.chunkStore.cachedChunks[chunkIDOfStartBin].xoffsets[relativePosInFile])
          xcoord = column * cellWidthMargin
        } else {
          column = startBin
          xcoord = startBin * cellWidthMargin
        }
        if (highlightColumn) {
          // We shift xcoord to center the view on the highlight
          xcoord = this.getShiftedX(xcoord)
          this.highlight(column)
          store.commit('graphStore/setSelectedHighlight', column)
        }
        matrixViewport.left = xcoord
        matrixViewport.top = viewTop
      } else {
        if (anchorViewport === 'left') {
          matrixViewport.left = viewAnchor
          matrixViewport.top = viewTop
        } else if (anchorViewport === 'right') {
          matrixViewport.right = viewAnchor
          matrixViewport.top = viewTop
        } else if (anchorViewport === 'undefined') {
          matrixViewport.center = viewCenter
          matrixViewport.top = viewTop
        }
      }

      this.updateBounce()
      this.alignContainers()
      loader.hide()
    })
  },

  alignContainers () {
    sequenceContainer.position = matrixViewport.position
    metaContainer.position.y = matrixViewport.position.y

    if (!store.state.graphStore.loading) {
      store.commit('uiStore/setSliderPosition', [
        this.getBinInfoForRawX(matrixViewport.left + store.state.metaStore.neededLeftMargin).binNumber,
        this.getBinInfoForRawX(matrixViewport.left + matrixViewport.screenWidth).binNumber
      ])
    }
  },

  drawChunk (chunkID: number) {
    return new Promise<void>((resolve) => {
      // The last x offset includes all arrivals/departures except the last one
      // TODO Check for arrival/departure on last bin
      const chunk = store.state.chunkStore.cachedChunks[chunkID]
      const binsInChunk = chunk.lastBin - chunk.firstBin + 1
      let columnsInChunk
      if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
        // bins plus links:
        columnsInChunk = binsInChunk + chunk.xoffsets[chunk.xoffsets.length - 1] - chunk.xoffsets[0] + 1
      } else {
        // only bins:
        columnsInChunk = binsInChunk
      }

      if (!drawnChunks.has(chunkID)) {
        store.commit('chunkStore/setCurrentChunk', chunkID)

        // Draw tracks
        const tracksContainer = this.drawTracks(chunk)

        // Draw sequence text
        if (!store.state.metaStore.denseView) {
          this.drawSequenceText(chunk, sequenceContainer)
        }

        // Shapes were drawn at a low x pos and now the whole tracks container is moved instead of the individual shapes
        let x
        if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
          x = store.state.metaStore.neededLeftMargin + ((chunk.firstBin + chunk.xoffsets[0]) * cellWidthMargin)
        } else {
          x = store.state.metaStore.neededLeftMargin + (chunk.firstBin * cellWidthMargin)
        }
        tracksContainer.position.set(x, 0)

        matrixViewport.addChild(tracksContainer)
        drawnChunks.add(+chunkID)
      } else {
        console.log('[DRAW] ALREADY DRAWN: chunk', chunkID)
      }

      resolve()
    })
  },

  drawTracks (chunkData: ChunkData): PIXI.Container {
    let y = topMargin
    let pathId = -1

    const tracksContainer = new PIXI.Container()
    const graphTracksGraphics = new PIXI.Graphics()
    const readTracksGraphics = new PIXI.Graphics()

    const tracks = this.getCurrentTracks()
    const graphTracks = tracks.graphTracks
    const readTracks = tracks.readTracks

    // If there are no tracks to draw, we show the placeholder
    if (graphTracks.length === 0 && readTracks.length === 0) {
      // Show panto placeholder
      store.commit('graphStore/setPlaceholderVisible', true)
    } else {
      // Hide panto placeholder
      store.commit('graphStore/setPlaceholderVisible', false)
    }

    // If graphTracks is empty here, it's because the user disabled all graphTracks manually
    // In that case, we still draw the first path in the list as a placeholder to prevent any bug
    // TODO: this is a hack, we should not draw anything if there are no graphTracks to draw
    if (graphTracks.length === 0) {
      graphTracksGraphics.alpha = 0
      graphTracks.push([...store.state.chunkStore.graphTracks.keys()][0])
    }

    for (const pathName of graphTracks) {
      pathId = pathId + 1

      if (!(pathName in chunkData.paths)) {
        y = y + cellHeight
        continue
      }
      const path = chunkData.paths[pathName]
      const style = Config.getGenomeNameTextStyle()
      y = y + cellHeight

      for (let i = 0; i < chunkData.xoffsets.length; i++) {
        // We do NOT draw it at firstBin, but only at i + offset and then move the whole graphics
        const bin = chunkData.firstBin + i
        let binColumn = bin
        let columnOfFirstBinInChunk = chunkData.firstBin
        if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
          binColumn += chunkData.xoffsets[i]
          columnOfFirstBinInChunk += chunkData.xoffsets[0]
        }
        let xInChunk
        if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
          xInChunk = i * cellWidthMargin
        } else {
          xInChunk = (i + chunkData.xoffsets[i] - chunkData.xoffsets[0]) * cellWidthMargin
        }
        const posInChunk = i + 1
        let metaDataColor = {}

        if (store.state.metaStore.drawLinks && path.links[i].length) {
          let outgoing = 0
          let incoming = 0

          for (let k = 0; k < path.links[i].length; k++) {
            const link = path.links[i][k]
            let globalColumn = 0
            let color
            const neighborLink = (Math.abs(Math.abs(link[2]) - Math.abs(link[1])) === 1)

            if (Math.abs(link[1]) - (chunkData.firstBin - 1) === posInChunk) {
              // Outgoing link
              if (store.state.metaStore.denseView) {
                globalColumn = binColumn
              } else {
                globalColumn = this.getColumnAndStoreColumnInfo(bin, binColumn, link[0], link[1] > 0, !neighborLink)
              }
              outgoing++

              // store link info for drawing them later
              if (store.state.metaStore.drawLinks) {
                color = this.getColorAndStoreLinkInfo(link, false, globalColumn, pathId)
              }

              // Draw the outgoing links (if not denseView)
              if (!store.state.metaStore.denseView) {
                if (link[1] < 0) {
                  if (neighborLink) {
                    this.drawNeighborLeftArrow(graphTracksGraphics, (globalColumn - columnOfFirstBinInChunk) * cellWidthMargin, y)
                  } else {
                    this.drawOutgoingLinkArrow(graphTracksGraphics, (globalColumn - columnOfFirstBinInChunk) * cellWidthMargin, y, color)
                  }
                } else {
                  if (neighborLink) {
                    this.drawNeighborRightArrow(graphTracksGraphics, (globalColumn - columnOfFirstBinInChunk) * cellWidthMargin, y)
                  } else {
                    this.drawOutgoingLinkArrow(graphTracksGraphics, (globalColumn - columnOfFirstBinInChunk) * cellWidthMargin, y, color)
                  }
                }

                if (link[3] > 1) {
                  // print number of times (=link[3]) the link is traversed consecutively on top of the cell:
                  const text = new PIXI.Text(link[3] > 9 ? '+' : link[3],
                    style).setTransform((globalColumn - columnOfFirstBinInChunk) * cellWidthMargin + cellMargin, y)
                  text.resolution = 3
                  graphTracksGraphics.addChild(text)
                }
              }
            } else if (Math.abs(link[2]) - (chunkData.firstBin - 1) === posInChunk && !neighborLink) {
              // Incoming link
              if (store.state.metaStore.denseView) {
                globalColumn = binColumn
              } else {
                globalColumn = this.getColumnAndStoreColumnInfo(bin, binColumn, link[0], link[2] < 0, true)
              }
              incoming++

              // store link info for drawing them later
              if (store.state.metaStore.drawLinks) {
                color = this.getColorAndStoreLinkInfo(link, true, globalColumn, pathId)
              }

              // Draw the incoming links (if not denseView)
              if (!store.state.metaStore.denseView) {
                if (link[2] < 0) {
                  this.drawIncomingLinkArrowLeft(graphTracksGraphics, (globalColumn - columnOfFirstBinInChunk) * cellWidthMargin, y, color)
                } else {
                  this.drawIncomingLinkArrowRight(graphTracksGraphics, (globalColumn - columnOfFirstBinInChunk) * cellWidthMargin, y, color)
                }
              }
            }

            // Sanity check; this should not occur
            if (!store.state.metaStore.denseView && globalColumn <= 0) {
              console.log('  bin', bin, 'binColumn', binColumn, 'globalCol', globalColumn,
                'i', i, 'columnOfFirstBinInChunk', columnOfFirstBinInChunk,
                'xInChunk', i + chunkData.xoffsets[i] - chunkData.xoffsets[0],
                'xoff[i]', chunkData.xoffsets[i],
                'xoff[i-1]', i - 1 >= 0 ? chunkData.xoffsets[i - 1] : '',
                'nr links', path.links[i].length, 'from/to', outgoing, incoming)
            }
          }
        }

        // Get bin color:
        if (store.state.metaStore.selectedMetadataToColor !== 'none') {
          // determine cell color by metadata
          const metadataValue = store.state.metaStore.metaData[pathName][store.state.metaStore.selectedMetadataToColor]
          metaDataColor = store.state.metaStore.colorLookupTable[store.state.metaStore.selectedMetadataToColor][metadataValue]
        }

        let cellColor = emptyColor
        if (path.covs[i] > 0) {
          if (store.state.metaStore.selectedMetadataToColor !== 'none' && metaDataColor !== '0xFFFFFF') {
            // color by metadata:
            cellColor = Number(metaDataColor)
          } else if (store.state.metaStore.drawInversions && path.invs[i] > 0 &&
            store.state.metaStore.drawDuplications && path.covs[i] > 1) {
            // draw purple inv + dupl
            cellColor = invDuplColor
          } else if (store.state.metaStore.drawInversions && path.invs[i] > 0) {
            // draw inversions in a shade of red
            cellColor = Number((invColorPaletteScale(path.invs[i]) as Color).hex().replace('#', '0x'))
          } else if (store.state.metaStore.drawDuplications && path.covs[i] > 1) {
            // blue for duplicated bin sequences
            if (path.covs[i] >= 8) {
              cellColor = spacerColor
            } else {
              cellColor = Number(duplColorPaletteArray[Math.trunc(path.covs[i] - 1)].replace('#', '0x'))
            }
          } else {
            // default fill color gradient based on covs
            cellColor = Number((fillColorPaletteScale(path.covs[i]) as Color).hex().replace('#', '0x'))
          }
        }

        // store bin in columnInfo
        const columnInfo = store.state.metaStore.columnInfo[binColumn]
        if (columnInfo === undefined) {
          store.commit('metaStore/addColumnInfo', {
            column: binColumn,
            columnInfo: {
              type: 'bin',
              bin
            }
          })
        }

        // color markers
        if (path.markers && path.markers[i] && path.markers[i] > 0) {
          cellColor = Config.markerColor
        }

        // draw the bands
        if (path.genes && path.genes[i].length) {
          // draw the cell together with a gene band
          let inExon = false
          // and if it's in an exon
          if (path.genes[i][2].length) {
            for (let g = 0; g < path.genes[i][2].length; g++) {
              if (path.genes[i][2][g].length) {
                inExon = true
              }
            }
          }

          // draw upper band
          graphTracksGraphics.beginFill(cellColor)
          graphTracksGraphics.drawRect(xInChunk, y, cellWidth, Math.floor((cellHeight - cellMargin) * Config.upperBandHeightRatio))
          graphTracksGraphics.endFill()

          // darkened fillColor if within a gene or exon:
          if (inExon) {
            cellColor = exonColor
          } else {
            cellColor = geneColor
          }
          // bottom gene band:
          graphTracksGraphics.beginFill(cellColor)
          graphTracksGraphics.drawRect(xInChunk, y + Math.floor((cellHeight - cellMargin) * Config.upperBandHeightRatio),
            cellWidth,
            Math.floor((cellHeight - cellMargin) * (1 - Config.upperBandHeightRatio)))
          graphTracksGraphics.endFill()
        } else if (cellColor !== emptyColor) {
          // draw the cell without a gene band if the cell color is not the background color
          graphTracksGraphics.beginFill(cellColor)
          graphTracksGraphics.drawRect(xInChunk, y, cellWidth, cellHeight - cellMargin)
          graphTracksGraphics.endFill()
        }

        // Draw readTracks
        if (store.state.metaStore.readsInFiles && readTracks && pathId + 1 >= graphTracks.length) {
          let counter = 0
          for (const track of readTracks) {
            const readsRow = y + cellHeight * (1 + Config.blankRowsBeforeReads + counter)
            const cov = track.bins[i].cov

            let mappedVal
            if (cov <= 1) {
              mappedVal = cov * 2 / 15
            } else if (cov <= 20) {
              mappedVal = (17 * cov + 59) / 570
            } else if (cov <= 100) {
              mappedVal = (7 * cov + 1540) / 2400
            } else {
              mappedVal = 1
            }
            mappedVal = (-mappedVal + 1) * 0xFF // invert range, map to 0,255

            let readColor = emptyColor
            readColor = mappedVal << 16 | mappedVal << 8 | mappedVal

            let noEdits = true
            // draw alternative seq in reads ('edits') as lower bands with their heights relative to their frequency:
            if (track.bins[i].edit) {
              const edit = track.bins[i].edit
              if (edit !== undefined) {
                noEdits = false
                const altCov = edit.A + edit.C + edit.G + edit.T + edit.dels
                const altRatio = altCov / (cov + edit.total)
                const yLowerBandHeight = Math.ceil((cellHeight - cellMargin) * altRatio)
                let yInsHeight = 0
                if (edit.ins.length) {
                  const insRatio = edit.ins.length / (cov + edit.total)
                  yInsHeight = Math.ceil((cellHeight - cellMargin) * insRatio)
                }
                const yUpperBandHeight = cellHeight - cellMargin - yLowerBandHeight - yInsHeight

                // draw the non-alt upper band:
                readTracksGraphics.beginFill(readColor)
                readTracksGraphics.drawRect(
                  xInChunk,
                  readsRow,
                  cellWidth,
                  yUpperBandHeight)
                readTracksGraphics.endFill()

                if (altCov > 0) {
                  readTracksGraphics.beginFill(Config.editColor)
                  readTracksGraphics.drawRect(
                    xInChunk,
                    readsRow + (cellHeight - cellMargin) - yLowerBandHeight,
                    cellWidth,
                    yLowerBandHeight)
                  readTracksGraphics.endFill()
                }
                // draw rectangles for insertions on top of other edits
                if (edit.ins.length) {
                  readTracksGraphics.beginFill(chroma(Config.editColor).darken(1.3).hex().replace('#', '0x'))
                  const path = [xInChunk, readsRow + yUpperBandHeight,
                    xInChunk + cellWidthMargin, readsRow + yUpperBandHeight,
                    xInChunk + (cellWidthMargin / 2), readsRow + yUpperBandHeight + yInsHeight
                  ]
                  readTracksGraphics.drawPolygon(path)
                  readTracksGraphics.endFill()
                }
              }
            } else if (track.bins[i].avg_edit_frc) {
              const frc = track.bins[i].avg_edit_frc
              if (frc !== undefined) {
                noEdits = false
                const yUpperBandHeight = Math.floor((cellHeight - cellMargin) * (1 - frc))
                const yLowerBandHeight = cellHeight - cellMargin - yUpperBandHeight

                // draw the non-alt upper band:
                readTracksGraphics.beginFill(readColor)
                readTracksGraphics.drawRect(
                  xInChunk,
                  readsRow,
                  cellWidth,
                  yUpperBandHeight)
                readTracksGraphics.endFill()

                // draw the lower alt band:
                readTracksGraphics.beginFill(Config.editColor)
                readTracksGraphics.drawRect(
                  xInChunk,
                  readsRow + yUpperBandHeight,
                  cellWidth,
                  yLowerBandHeight)
                readTracksGraphics.endFill()
              }
            }

            if (noEdits) {
              // there are no edits:
              readTracksGraphics.beginFill(readColor)
              readTracksGraphics.drawRect(
                xInChunk,
                readsRow,
                cellWidth,
                cellHeight - cellMargin)
              readTracksGraphics.endFill()
            }

            counter++
          }
        }
      }
    }

    // With a high cov_fraction threshold, it can happen that some columns are not drawn.
    // To include them in the columnInfo (needed to read start and end of current view for the slider on top),
    // we have to fill them completely and for simplicity, we just iterate all the columns of the chunk again
    const firstCol = (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) ? chunkData.firstBin : chunkData.firstCol
    const lastCol = (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) ? chunkData.lastBin : chunkData.lastCol
    let lastColInfo = firstCol
    for (let pos = firstCol; pos <= lastCol; ++pos) {
      if (store.state.metaStore.columnInfo[pos] === undefined) {
        store.commit('metaStore/addColumnInfo', {
          column: pos,
          columnInfo: {
            type: 'bin',
            lastColInfo
          }
        })
      } else {
        lastColInfo = pos
      }
    }

    tracksContainer.addChild(graphTracksGraphics)
    tracksContainer.addChild(readTracksGraphics)
    return tracksContainer
  },

  drawSequenceText (chunk: ChunkData, container: PIXI.Container) {
    if (store.state.chunkStore.binWidth !== 1) {
      return
    }

    let seq = chunk.sequence
    if (store.state.metaStore.drawLinks) {
      for (let i = chunk.xoffsets.length - 1; i > 0; i--) {
        // if next item in offsets differs -> space / gap -> add spacer to seq
        const diff = chunk.xoffsets[i] - chunk.xoffsets[i - 1]
        if (diff > 0) {
          const spacer = ' '.repeat(diff)
          seq = seq.substring(0, i) + spacer + seq.substring(i, seq.length)
        }
      }
    }

    let seqX = store.state.metaStore.neededLeftMargin + chunk.firstBin * cellWidthMargin
    if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
      seqX += chunk.xoffsets[0] * cellWidthMargin
    }
    const seqY = 0

    let bitmapText: PIXI.BitmapText
    const fontSize = (cellWidthMargin === cellWidth) ? Config.fontSizeWoMargin : Config.fontSize

    if (bitmapFont) {
      bitmapText = new PIXI.BitmapText(seq, {
        fontName: 'MonoBitmapFont',
        fontSize
      }).setTransform(seqX, seqY)

      container.addChild(bitmapText)
    }
  },

  drawMetaContainer (container: PIXI.Container) {
    const graphics = new PIXI.Graphics()
    const style = Config.getGenomeNameTextStyle()

    const numberPaths = this.getCurrentTracks().graphTracks.length
    const numberReadTracks = store.state.metaStore.readsetNames.length
      ? store.state.metaStore.readsetNames.length + Config.blankRowsBeforeReads
      : 0
    const yTop = -Config.maxNrLinks * cellHeight
    const height = -yTop + (numberPaths + numberReadTracks) * cellHeight + topMargin + cellHeight
    const yPathnames = topMargin + cellHeight

    this.addContainerBackground(container,
      height,
      store.state.metaStore.neededLeftMargin,
      PIXI.Texture.WHITE, yTop, 1)

    let metadata
    let lookupTable
    let nrMetaCols = 0

    if (store.state.metaStore.drawMetaData) {
      metadata = store.state.metaStore.metaData
      lookupTable = store.state.metaStore.colorLookupTable
      nrMetaCols = Object.keys(lookupTable).length
    }

    let textLen = 0
    let y = yPathnames

    // draw path metadata heatmaps:
    for (const pathName of this.getCurrentTracks().graphTracks) {
      if (store.state.metaStore.drawMetaData && pathName in metadata) {
        const currentMetadata = metadata[pathName]
        const metaDataNames = Object.keys(lookupTable)

        let x = 0
        for (let j = 0; j < nrMetaCols; j++) {
          graphics.beginFill(lookupTable[metaDataNames[j]][currentMetadata[metaDataNames[j]]])
          graphics.drawRect(x, y, Config.cellWidth, Config.cellWidth)
          graphics.endFill()

          // even in dense view mode, we draw full-sized quadratic cells with dimensions cellHeight x cellHeight
          x = x + cellHeight
        }
      }

      // draw path names:
      const text = new PIXI.Text(pathName, style).setTransform(nrMetaCols * cellHeight, y)
      text.resolution = 3

      container.addChild(text)

      y = y + cellHeight
    }

    y = y + (Config.blankRowsBeforeReads * (Config.cellHeight + Config.cellMargin))

    // draw readset names
    if (store.state.metaStore.readsInFiles) {
      const readTracks = this.getCurrentTracks().readTracks
      for (let i = 0; i < readTracks.length; i++) {
        const sampleName = readTracks[i].readset_name
        const textMetric: PIXI.TextMetrics = PIXI.TextMetrics.measureText(sampleName, style)
        if (textLen < textMetric.width) {
          textLen = textMetric.width
        }

        const text = new PIXI.Text(sampleName, style).setTransform(nrMetaCols * cellHeight, y)
        text.resolution = 3

        container.addChild(text)

        // draw read's metadata if available:
        if (sampleName in metadata) {
          const readMetadata = metadata[sampleName]
          const metaDataNames = Object.keys(lookupTable)
          let x = 0
          for (let j = 0; j < nrMetaCols; j++) {
            graphics.beginFill(lookupTable[metaDataNames[j]][readMetadata[metaDataNames[j]]])
            graphics.drawRect(x, y, Config.cellWidth, Config.cellWidth)
            graphics.endFill()

            // even in dense view mode, we draw full-sized quadratic cells with dimensions cellHeight x cellHeight
            x = x + Config.cellWidth + Config.cellMargin
          }
        }

        y = y + cellHeight
      }
    }

    // Draw sortingTools
    sortingTools = new SortingTools(metaContainer)

    container.addChild(graphics)
  },

  addContainerBackground (container: PIXI.Container, height: number, width: number, color: PIXI.Texture, topOffset: number, alpha: number): PIXI.Sprite {
    const background = new PIXI.Sprite(color)

    background.height = height
    background.width = width
    background.position.y = topOffset
    background.alpha = alpha

    container.addChild(background)

    return background
  },

  drawArcLinks (graphics: PIXI.Graphics, startX: number, endX: number) {
    startX = startX * cellWidthMargin
    endX = endX * cellWidthMargin

    graphics.beginFill(0xFFFFFF, 0)
    graphics.lineStyle(1, 0x000000, 0.2)
    graphics.arc(Math.min(startX, endX) + Math.abs((endX - startX) / 2),
      topMargin + cellHeight - cellMargin * 2,
      Math.abs((endX - startX) / 2),
      Math.PI, 0)
    graphics.endFill()
  },

  drawArrow (graphics: PIXI.Graphics, startX: number, endX: number, color: number, linkHeight: number, outgoing = true, incoming = true) {
    let start = 0
    let end = 0

    start = startX * cellWidthMargin
    end = endX * cellWidthMargin

    let arrowHeight = -(linkHeight + 2) * cellHeight
    cellHeight -= cellMargin // reset at the end of the function
    const base = topMargin + 10
    // path variable contains the path in the [x1, y1, x2, y2, .. , xn, yn] format
    // with x being the horizontal axis and y being the vertical axis
    let path = []

    if (outgoing && incoming) {
      if (endX >= startX) {
        path = [start, base,
          start, arrowHeight,
          end + cellWidth, arrowHeight,
          end + cellWidth, base - cellHeight / 2,
          end + cellWidth / 2, base,
          end, base - cellHeight / 2,
          end, arrowHeight + cellHeight,
          start + cellWidth, arrowHeight + cellHeight,
          start + cellWidth, base]
      } else {
        path = [start + cellWidth, base,
          start + cellWidth, arrowHeight,
          end, arrowHeight,
          end, base - cellHeight / 2,
          end + cellWidth / 2, base,
          end + cellWidth, base - cellHeight / 2,
          end + cellWidth, arrowHeight + cellHeight,
          start, arrowHeight + cellHeight,
          start, base]
      }
      graphics.beginFill(color, 0.8)
    } else if (outgoing) {
      arrowHeight = base - cellHeight
      path = [start, base,
        start, arrowHeight,
        start + cellWidth / 2, arrowHeight - cellHeight / 2,
        start + cellWidth, arrowHeight,
        start + cellWidth, base]
      graphics.beginFill(color, 1)
    } else if (incoming) {
      arrowHeight = base - cellHeight - cellHeight / 2
      path = [end, base - cellHeight / 2,
        end, arrowHeight,
        end + cellWidth, arrowHeight,
        end + cellWidth, base - cellHeight / 2,
        end + cellWidth / 2, base]
      graphics.beginFill(color, 1)
    } else {
      path = [0, 0,
        2000, 0,
        2000, 6000,
        0, 6000]
      graphics.beginFill(0xfc0303, 1)
    }

    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()

    cellHeight += cellMargin
  },

  drawNeighborRightArrow (graphics: PIXI.Graphics, xstart: number, ystart: number) {
    cellHeight -= cellMargin
    const path = [xstart, ystart,
      xstart + cellWidth / 2, ystart,
      xstart + cellWidth, ystart + cellHeight / 2,
      xstart + cellWidth / 2, ystart + cellHeight,
      xstart, ystart + cellHeight]
    cellHeight += cellMargin

    graphics.beginFill(nextArrowColor, 1)
    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawNeighborLeftArrow (graphics: PIXI.Graphics, xstart: number, ystart: number) {
    cellHeight -= cellMargin
    const path = [xstart + cellWidth, ystart,
      xstart + cellWidth / 2, ystart,
      xstart, ystart + cellHeight / 2,
      xstart + cellWidth / 2, ystart + cellHeight,
      xstart + cellWidth, ystart + cellHeight]
    cellHeight += cellMargin

    graphics.beginFill(nextArrowColor, 1)
    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawOutgoingLinkArrow (graphics: PIXI.Graphics, xstart: number, ystart: number, color: number) {
    cellHeight -= cellMargin
    const path = [xstart + cellWidth / 2, ystart,
      xstart + cellWidth, ystart + cellHeight / 2,
      xstart + cellWidth, ystart + cellHeight,
      xstart, ystart + cellHeight,
      xstart, ystart + cellHeight / 2]
    cellHeight += cellMargin

    graphics.beginFill(color, 1)
    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawIncomingLinkArrow (graphics: PIXI.Graphics, xstart: number, ystart: number, color: number) {
    cellHeight -= cellMargin
    const path = [xstart + 2 * cellWidth / 5, ystart,
      xstart + 2 * cellWidth / 5, ystart + cellHeight,
      xstart + 3 * cellWidth / 5, ystart + cellHeight,
      xstart + cellWidth, ystart + 2 * cellHeight / 3,
      xstart + 3 * cellWidth / 5, ystart + cellHeight / 3,
      xstart + 3 * cellWidth / 5, ystart]
    cellHeight += cellMargin

    graphics.beginFill(color, 1)
    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawIncomingLinkArrowLeft (graphics: PIXI.Graphics, xstart: number, ystart: number, color: number) {
    cellHeight -= cellMargin
    const path = [xstart + 3 * cellWidth / 4, ystart + cellHeight / 4,
      xstart + cellWidth / 4, ystart + cellHeight / 2,
      xstart + 3 * cellWidth / 4, ystart + 3 * cellHeight / 4]
    cellHeight += cellMargin

    graphics.beginFill(color, 1)
    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawIncomingLinkArrowRight (graphics: PIXI.Graphics, xstart: number, ystart: number, color: number) {
    cellHeight -= cellMargin
    const path = [
      xstart + cellWidth / 4, ystart + cellHeight / 4,
      xstart + 3 * cellWidth / 4, ystart + cellHeight / 2,
      xstart + cellWidth / 4, ystart + 3 * cellHeight / 4]
    cellHeight += cellMargin

    graphics.beginFill(color, 1)
    graphics.lineStyle(0)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawSepLine (graphics: PIXI.Graphics, xstart: number, ystart: number) {
    cellHeight -= cellMargin
    const path = [
      xstart, ystart,
      xstart, ystart + cellHeight
    ]
    cellHeight += cellMargin
    graphics.beginFill(0x000000, 1)
    graphics.lineStyle(1)
    graphics.drawPolygon(path)
    graphics.endFill()
  },

  drawConnectedLinks (viewport: Viewport) {
    const graphics = new PIXI.Graphics()
    const firstBin = store.state.chunkStore.currentFirstBin
    let columnOfFirstBinInChunk = firstBin
    let xOffsetOfFirstBinInChunk
    if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
      const fileidx = LocalFileProvider.getFileIndexForBinPos(firstBin)
      xOffsetOfFirstBinInChunk = store.state.chunkStore.cachedChunks[fileidx].xoffsets[0]
      columnOfFirstBinInChunk += xOffsetOfFirstBinInChunk
    }

    if (!store.state.metaStore.denseView) {
      let minHeight = 0

      // linkColumns store all the columns that are links, and not for neighboring links
      // sort and iterate (with that we avoid to iterate over columnInfo which contains mostly bins)
      store.state.metaStore.linkColumns.sort()

      for (let i = 0; i < store.state.metaStore.linkColumns.length; ++i) {
        const column = store.state.metaStore.linkColumns[i]
        const linkID = store.state.metaStore.columnInfo[column].linkId
        const linkInfo = store.state.metaStore.linkInfo[linkID]

        // do nothing for neighboring links
        if (Math.abs(Math.abs(linkInfo.toBin) - Math.abs(linkInfo.fromBin)) === 1) {
          continue
        }

        if (linkInfo.height === undefined) {
          // first occurrence of the link.
          // It gets a height by searching the minimum idx where linkHeight is false
          for (; minHeight < Config.maxNrLinks; ++minHeight) {
            if (!linkHeights[minHeight]) break
          }
          linkInfo.height = minHeight
          linkHeights[minHeight] = true
        } else if (linkInfo.connected && column === Math.max(linkInfo.fromColumn, linkInfo.toColumn)) {
          // second occurrence of the link, and link is 'visible' since both ends are in currently loaded chunks
          // link height should be freed again, and link is drawn
          linkHeights[linkInfo.height] = false

          // adapt minHeight to directly point to the lowest available height
          if (linkInfo.height < minHeight) {
            minHeight = linkInfo.height
          }

          if (linkInfo.height > store.state.metaStore.maxLinkHeight) {
            store.commit('metaStore/setMaxLinkHeight', linkInfo.height)
          }

          // draw link:
          this.drawArrow(graphics,
            linkInfo.fromColumn - columnOfFirstBinInChunk,
            linkInfo.toColumn - columnOfFirstBinInChunk,
            linkInfo.color, linkInfo.height, true, true)
        }
      }
    } else {
      // draw arc:
      Object.keys(store.state.metaStore.linkInfo).forEach((linkId) => {
        if (store.state.metaStore.linkInfo[linkId].connected) {
          this.drawArcLinks(graphics,
            Math.abs(store.state.metaStore.linkInfo[linkId].fromBin) - firstBin,
            Math.abs(store.state.metaStore.linkInfo[linkId].toBin) - firstBin)
        }
      })
    }

    // The arrows/arcs have been drawn at a low x pos and now the graphics container is moved
    // to the correct position, to avoid moving each individual shape
    let x
    if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
      x = store.state.metaStore.neededLeftMargin + ((firstBin + xOffsetOfFirstBinInChunk) * cellWidthMargin)
    } else {
      x = store.state.metaStore.neededLeftMargin + ((firstBin) * cellWidthMargin)
    }
    graphics.position.set(x, 0)

    // TODO: do not forget to mention in the docs that we have a max nr link param, set to 10,000 currently

    viewport.addChild(graphics)
  },

  getColumnAndStoreColumnInfo (bin: number, column: number, linkId: number, arrival: boolean, noNeighborLink: boolean) {
    const columnBefore = column
    let columnObj
    do {
      if (arrival) {
        column++
      } else {
        column--
      }
      columnObj = store.state.metaStore.columnInfo[column]
    }
    while (columnObj !== undefined && columnObj.linkId !== linkId)

    if (column <= 0) {
      // console.log('bin', bin, arrival ? 'to' : 'from', '- link @col', columnBefore,
      //   '(id', linkId, ')  --->  columnAfter:', column)
    }

    if (columnObj === undefined) {
      store.commit('metaStore/addColumnInfo', {
        column,
        columnInfo: {
          type: 'link',
          linkId,
          bin
        }
      })
      if (noNeighborLink) {
        store.commit('metaStore/addLinkColumn', column)
      }
    }

    return column
  },

  getColorAndStoreLinkInfo (linkInfo: Array<number>, arrival: boolean, linkColumn: number, pathId: number) {
    let color
    const linkId = linkInfo[0]
    const fromBin = linkInfo[1]
    const toBin = linkInfo[2]

    // we'll use the link ID as key for indexing
    let storedLinkInfo = store.state.metaStore.linkInfo[linkId]

    // Notes:
    // - drawnTo positions are relative to the current chunk
    // - from/to are global positions
    // - offsets are global xoffsets

    // use stored information about a partially visited arrow...
    if (storedLinkInfo !== undefined) {
      color = storedLinkInfo.color

      if (arrival) {
        storedLinkInfo.toColumn = linkColumn
      } else {
        storedLinkInfo.fromColumn = linkColumn
      }

      if (!storedLinkInfo.paths.includes(pathId)) {
        storedLinkInfo.paths.push(pathId)
      }

      // only if from and to position of link has been seen, mark link for drawing:
      if ('toColumn' in storedLinkInfo && 'fromColumn' in storedLinkInfo) {
        storedLinkInfo.connected = true
      }
      store.commit('metaStore/addLinkInfo', { linkId, linkInfo: storedLinkInfo })
    } else {
      color = store.state.metaStore.denseView
        ? Config.arcColor
        : this.getNextLinkColor(linkId, arrival, Math.abs(toBin), Math.abs(fromBin))
      storedLinkInfo = {
        linkId,
        linkInfo: {
          color,
          fromBin, // can be negative
          toBin, // can be negative
          connected: false,
          drawn: false,
          paths: []
        }
      }

      if (arrival) {
        storedLinkInfo.linkInfo.toColumn = linkColumn
      } else {
        storedLinkInfo.linkInfo.fromColumn = linkColumn
      }

      if (!storedLinkInfo.linkInfo.paths.includes(pathId)) {
        storedLinkInfo.linkInfo.paths.push(pathId)
      }

      store.commit('metaStore/addLinkInfo', storedLinkInfo)
    }

    return color
  },

  getNextLinkColor (linkId: number, arrival: boolean, upstream: number, downstream: number) {
    const zoomLevel = LocalFileProvider.getZoomLevelObj(store.state.chunkStore.binWidth)

    switch (store.state.metaStore.selectedLinkType) {
      case 'distance':
        // TODO: why not just abs(upstream-downstream)? num_bins is irrelevant, no?
        if (arrival) {
          return linkColorPalette(upstream / zoomLevel.num_bins).hex().replace('#', '0x')
        } else {
          return linkColorPalette(downstream / zoomLevel.num_bins).hex().replace('#', '0x')
        }
      default:
        return chroma.brewer.Paired[linkId % 12].replace('#', '0x')
    }
  },

  getTrackNameByCoordinate (y: number) {
    const row = this.getRowForCoordinate(y)
    if (row < 0) {
      return ''
    }

    let trackName = ''
    const paths = this.getCurrentTracks().graphTracks
    const nrTracks = paths.length - 1 + Config.blankRowsBeforeReads + store.state.metaStore.readsetNames.length
    if (row < paths.length) {
      trackName = paths[row]
    } else if (row > paths.length - 1 + Config.blankRowsBeforeReads && row <= nrTracks) {
      trackName = store.state.metaStore.readsetNames[row - paths.length - Config.blankRowsBeforeReads]
    }

    return trackName
  },

  getBinInfoForRawX (x: number, sorting = false): BinInfo {
    let maxX
    if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
      maxX = store.state.metaStore.neededLeftMargin + store.state.chunkStore.currentLastColumn * cellWidthMargin
    } else {
      maxX = store.state.metaStore.neededLeftMargin + store.state.chunkStore.currentLastBin * cellWidthMargin
    }

    if (x > maxX) {
      // x = maxX
      const binInfo: BinInfo = { type: 'none', binNumber: store.state.chunkStore.currentLastBin }
      return binInfo
    }
    // console.log('x queried', x, 'maxX', maxX, 'currentLastColumn', store.state.chunkStore.currentLastColumn, 'currentLastBin', store.state.chunkStore.currentLastBin, '--->>> column', this.getColumnForCoordinate(x))
    return this.getBinInfoForColumn(this.getColumnForCoordinate(x), sorting)
  },

  getBinInfoForColumn (column: number, sorting = false): BinInfo {
    const binInfo: BinInfo = { type: 'none', binNumber: store.state.chunkStore.currentFirstColumn }

    for (const chunk of Object.values(store.state.chunkStore.cachedChunks as Record<string, ChunkData>)) {
      if ((store.state.metaStore.drawLinks && !store.state.metaStore.denseView &&
          column >= (chunk.firstBin + chunk.xoffsets[0]) && column <= chunk.lastCol) ||
        ((!store.state.metaStore.drawLinks || store.state.metaStore.denseView) &&
          column >= chunk.firstBin && column <= chunk.lastBin)) {
        // console.log('colInfo[', column, ']:', store.state.metaStore.columnInfo[column])

        if (store.state.metaStore.columnInfo[column]) {
          binInfo.binNumber = store.state.metaStore.columnInfo[column].bin
          binInfo.type = store.state.metaStore.columnInfo[column].type
        } else {
          // When does that case happen?
          console.log('WARNING: attempt to get columnInfo from column', column, 'currLastCol:', store.state.chunkStore.currentLastColumn, 'currLastBin', store.state.chunkStore.currentLastColumn)
          // binInfo.binNumber = store.state.chunkStore.currentLastBin
          // binInfo.type = 'bin'
          return binInfo
        }

        if (sorting) {
          if (store.state.metaStore.columnInfo[column].type === 'bin') {
            const relativeBinInChunk = store.state.metaStore.columnInfo[column].bin - chunk.firstBin
            // for bins:
            for (const p of this.getCurrentTracks().graphTracks) {
              if (p in chunk.paths) {
                Vue.set(store.state.metaStore.sortingTable, p, chunk.paths[p].covs[relativeBinInChunk])
              } else {
                Vue.set(store.state.metaStore.sortingTable, p, 0)
              }
            }
            return binInfo
          } else {
            // for links:
            const linkId = store.state.metaStore.columnInfo[column].linkId
            const link = store.state.metaStore.linkInfo[linkId]

            const graphTracksKeys = this.getCurrentTracks().graphTracks
            for (let k = 0; k < graphTracksKeys.length; k++) {
              if (link.paths.includes(k)) {
                Vue.set(store.state.metaStore.sortingTable, graphTracksKeys[k], link.paths.length)
              } else {
                Vue.set(store.state.metaStore.sortingTable, graphTracksKeys[k], 0)
              }
            }
            return binInfo
          }
        }
        return binInfo
      }
    }

    // one possibility to end up here is if a chunk starts with links rather than bins, then
    // column is smaller than chunk.firstBin + chunk.offsets[0]
    return binInfo
  },

  // getColumnForBin (bin: number, filename: string | null = null) {
  //   let col
  //   if (store.state.metaStore.drawLinks && !store.state.metaStore.denseView) {
  //     if (!filename) {
  //       const fileIndex = LocalFileProvider.getFileIndexForBinPos(bin - 1)
  //       filename = LocalFileProvider.getFileName(fileIndex)
  //     }

  //     const idx = bin - store.state.chunkStore.cachedChunks[filename].firstBin
  //     col = bin + store.state.chunkStore.cachedChunks[filename].xoffsets[idx]
  //   } else {
  //     col = bin
  //   }

  //   return col
  // },

  getRowForCoordinate (y: number) {
    return Math.floor((y - topMargin - cellHeight) / cellHeight)
  },

  getColumnForCoordinate (x: number) {
    return Math.floor((x - store.state.metaStore.neededLeftMargin) / cellWidthMargin)
  },

  // Check if a cell has a gene (first gene only)
  cellHasGene (x: number, y: number) : boolean {
    const col = this.getColumnForCoordinate(x)
    if (col <= 0) return false
    if (!this.columnInfoHasCol(col)) return false

    const row = this.getRowForCoordinate(y)

    if (store.state.metaStore.columnInfo[col]) {
      // look for the correct bin to search bin information
      let chunk
      const fileIdx = LocalFileProvider.getFileIndexForBinPos(col)
      if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
        chunk = store.state.chunkStore.cachedChunks[fileIdx]
      } else {
        for (const c of Object.values(store.state.chunkStore.cachedChunks as Record<number, ChunkData>)) {
          if (col >= c.firstCol && col <= c.lastCol) {
            chunk = c
            break
          }
        }
      }
      if (!chunk) return false

      const i = store.state.metaStore.columnInfo[col].bin - chunk.firstBin
      const paths = this.getCurrentTracks().graphTracks
      const pathName = paths[row]

      if (typeof chunk.paths[pathName] !== 'undefined' && chunk.paths[pathName].genes !== 'undefined') {
        if (chunk.paths[pathName].genes && chunk.paths[pathName].genes[i] && chunk.paths[pathName].genes[i].length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  },

  // Get gene name and strand (first gene only) -- used in GeneMenu component
  getGeneInfos (x: number, y: number) : {name: string, strand: string} {
    let name = ''
    let strand = ''

    const col = this.getColumnForCoordinate(x)
    if (col <= 0 || !this.columnInfoHasCol(col)) {
      return {
        name: name,
        strand: strand
      }
    }
    const row = this.getRowForCoordinate(y)

    // look for the correct bin to search bin information
    let chunk
    const fileIdx = LocalFileProvider.getFileIndexForBinPos(col)
    if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
      chunk = store.state.chunkStore.cachedChunks[fileIdx]
    } else {
      for (const c of Object.values(store.state.chunkStore.cachedChunks as Record<number, ChunkData>)) {
        if (col >= c.firstCol && col <= c.lastCol) {
          chunk = c
          break
        }
      }
    }

    const paths = this.getCurrentTracks().graphTracks
    const pathName = paths[row]
    const i = store.state.metaStore.columnInfo[col].bin - chunk.firstBin

    if (chunk.paths[pathName].genes && chunk.paths[pathName].genes[i] && chunk.paths[pathName].genes[i].length) {
      const gene = chunk.paths[pathName].genes[i]
      for (let g = 0; g < gene[0].length; ++g) {
        name = gene[0][g]
        strand = gene[1][g] ? 'Forward strand' : 'Reverse strand'
      }
    }

    return {
      name: name,
      strand: strand
    }
  },

  // Check if column is link. Used by right click menu to show/hide 'Follow link' option
  checkLink (x: number, y: number): boolean {
    const col = this.getColumnForCoordinate(x)
    if (!this.columnInfoHasCol(col)) return false
    if (store.state.metaStore.columnInfo[col] && store.state.metaStore.columnInfo[col].type === 'link') {
      return true
    } else {
      return false
    }
  },

  // Follow link. Bound to right click menu
  followLink (x: number, y: number) {
    const col = this.getColumnForCoordinate(x)
    if (col < 0) return

    const link = store.state.metaStore.linkInfo[store.state.metaStore.columnInfo[col].linkId]

    // jump to other end of link !
    if (col === link.toColumn) {
      store.state.graphStore.loading = true
      this.resetStage()
      this.loadAndDrawChunks(Math.abs(link.fromBin), true, true).then(() => {
        store.state.graphStore.loading = false
      }).catch((error) => {
        console.log(error)
      })
    } else if (col === link.fromColumn) {
      store.state.graphStore.loading = true
      this.resetStage()
      this.loadAndDrawChunks(Math.abs(link.toBin), true, true).then(() => {
        store.state.graphStore.loading = false
      }).catch((error) => {
        console.log(error)
      })
    }
  },

  getCellInfo (x: number, y: number) {
    const col = this.getColumnForCoordinate(x)
    const row = this.getRowForCoordinate(y)
    if (row < 0) return ''

    const graphTracks = this.getCurrentTracks().graphTracks
    const readTracks = this.getCurrentTracks().readTracks

    let numberTracks = graphTracks.length
    if (store.state.metaStore.readsInFiles) {
      numberTracks += store.state.metaStore.readsetNames.length + Config.blankRowsBeforeReads
    }

    let info = ''
    if (row >= numberTracks ||
      (store.state.metaStore.drawLinks && !store.state.metaStore.denseView &&
        col > store.state.chunkStore.currentLastColumn) ||
      ((!store.state.metaStore.drawLinks || store.state.metaStore.denseView) &&
        col > store.state.chunkStore.currentLastBin)) {
      return info
    }

    // if it's a link:
    if (store.state.metaStore.columnInfo[col] && store.state.metaStore.columnInfo[col].type === 'link') {
      const link = store.state.metaStore.linkInfo[store.state.metaStore.columnInfo[col].linkId]
      // return tooltip with link information
      const diff = Number(Math.abs(link.fromBin) - Math.abs(link.toBin))
      let updown
      if (diff > 0) {
        updown = ' downstream to pos '
      } else {
        updown = ' upstream to pos '
      }
      return '</br>Link Info</br>From pos ' +
        GeneralUtils.numberWithCommas(link.fromBin) +
        '</br>' + Math.abs(diff) + updown +
        GeneralUtils.numberWithCommas(link.toBin)
    }

    // if we are here: column is obviously a bin (if denseView is activated, there are only bins)

    // look for the correct bin to search bin information
    let chunk
    let chunkID = LocalFileProvider.getFileIndexForBinPos(col)
    if (!store.state.metaStore.drawLinks || store.state.metaStore.denseView) {
      chunk = store.state.chunkStore.cachedChunks[chunkID]
    } else {
      for (const c of Object.values(store.state.chunkStore.cachedChunks as Record<number, ChunkData>)) {
        if (col >= c.firstCol && col <= c.lastCol) {
          chunk = c
          chunkID = LocalFileProvider.getFileIndexForBinPos(c.firstBin)
          break
        }
      }
    }

    if (!chunk) return ''
    if (!this.columnInfoHasCol(col)) return ''

    const i = store.state.metaStore.columnInfo[col].bin - chunk.firstBin
    // console.log('col/chunk/1stbin/colInfo@col/i', col, chunkID, chunk.firstBin, store.state.metaStore.columnInfo[col].bin, i)

    if (row <= graphTracks.length) {
      const pathName = graphTracks[row]
      if (!(pathName in chunk.paths)) {
        return info
      }
      // console.log('row', row, 'path', pathName)

      const cov = chunk.paths[pathName].covs[i]
      const inv = chunk.paths[pathName].invs[i]

      if (chunk.paths[pathName].ranges && (cov > 0 || inv > 0)) {
        info += 'Pos: '
        for (let c = 0; c < Math.min(chunk.paths[pathName].ranges[i].length, 3); ++c) {
          const idx = (c === 2 ? chunk.paths[pathName].ranges[i].length - 1 : c)
          if (c > 0) {
            info += '; '
          }
          if (c === 2 && chunk.paths[pathName].ranges[i].length > 3) {
            info += '..., '
          }
          if (chunk.paths[pathName].ranges[i][idx][0] === 0) {
            info += GeneralUtils.numberWithCommas(chunk.paths[pathName].ranges[i][idx][1])
          } else {
            info += GeneralUtils.numberWithCommas(chunk.paths[pathName].ranges[i][idx][0]) + '-' +
              GeneralUtils.numberWithCommas(chunk.paths[pathName].ranges[i][idx][1])
          }
        }
      }
      if (cov >= 0) {
        info += '</br>Cov.: ' + cov
      }
      if (inv) {
        info += '</br>Inv.: ' + inv
      }

      // in a gene:
      if (chunk.paths[pathName].genes && chunk.paths[pathName].genes[i] && chunk.paths[pathName].genes[i].length) {
        info += '</br>Gene(s):'
        const gene = chunk.paths[pathName].genes[i]
        for (let g = 0; g < gene[0].length; ++g) {
          info += '</br>' + gene[0][g] + ', ' +
            (gene[1][g] ? 'forward strand' : 'reverse strand')
          if (store.state.chunkStore.binWidth <= Config.tooltipInfoSkip &&
            gene[2][g].length > 0) {
            info += (gene[2][g]
              ? ', exon' + (gene[2][g].length > 1 ? 's ' : ' ') +
              gene[2][g].join(',')
              : '')
          }
        }
      }

      // at a marker:
      if (chunk.paths[pathName].markers && chunk.paths[pathName].markers[i] && chunk.paths[pathName].markers[i] > 0) {
        info += '</br>' + chunk.paths[pathName].markers[i] + ' markers'
      }
    } else if (store.state.metaStore.readsInFiles) { // we are at the reads
      const rowInReads = row - this.getCurrentTracks().graphTracks.length - Config.blankRowsBeforeReads // 0-based
      // console.log('read index:', rowInReads)
      if (rowInReads < 0) {
        // we are between graph tracks and read tracks
        return ''
      } else {
        store.commit('chunkStore/setCurrentChunk', chunkID)
        if (readTracks.length > 0) {
          const cov = readTracks[rowInReads].bins[i].cov

          // Show also edits or edit fraction in readTracks, if present:
          if (store.state.chunkStore.binWidth === 1) {
            if (!('edit' in readTracks[rowInReads].bins[i])) {
              info += 'Cov: ' + cov
            } else {
              const edits: EditObject = readTracks[rowInReads].bins[i].edit as EditObject || {}
              info += 'Cov: ' + (cov + edits.total)
              let counter = 0
              if (edits.total) {
                const altPercent = Math.round(100 * edits.total / (edits.total + readTracks[rowInReads].bins[i].cov))
                info += '</br>' + altPercent + '% Alt: '
                if (edits.A) info += (counter++ > 0 ? ', ' : '') + 'A:' + edits.A
                if (edits.C) info += (counter++ > 0 ? ', ' : '') + 'C:' + edits.C
                if (edits.G) info += (counter++ > 0 ? ', ' : '') + 'G:' + edits.G
                if (edits.T) info += (counter++ > 0 ? ', ' : '') + 'T:' + edits.T
                if (edits.dels) info += (counter++ > 0 ? ', ' : '') + 'del:' + edits.dels
                if (edits.ins.length) info += (counter++ > 0 ? ', ' : '') + 'ins[#' + edits.ins.length + ']:' + edits.ins.join() // + '(' + String(edits.ins[0]).length + 'bp)'
              }
            }
          } else { // bin width !== 1
            // TODO: calc correct alt fraction if cov is <1
            info += 'Cov: ' + readTracks[rowInReads].bins[i].cov
            const frc = readTracks[rowInReads].bins[i].avg_edit_frc
            if (typeof frc !== 'undefined' && frc > 0) {
              info += '</br>' + Math.round(frc * 100) + '% Alt'
            }
          }
        }
      }
    }
    return info
  },

  columnInfoHasCol (col: number) {
    // the following happens only if the first columns in a chunk are
    // links. This is tricky to determine, so we prevent a tooltip in this case:
    if (col in store.state.metaStore.columnInfo) {
      return true
    } else {
      return false
    }
  },

  getMetaColumForCoordinate (x: number) {
    if (x > store.state.metaStore.neededLeftMargin) {
      return null
    }

    const col = Math.floor(x / (Config.cellWidth + Config.cellMargin))

    if (col <= Object.keys(store.state.metaStore.metaDataCategories).length) {
      return col
    } else {
      return null
    }
  },

  getBinAtViewportLeft () {
    return this.getBinInfoForRawX(matrixViewport.left + store.state.metaStore.neededLeftMargin).binNumber // + store.state.metaStore.neededLeftMargin)
  },

  cleanupOnDatasetChange () {
    // called only on dataset change
    store.commit('indexStore/setPathMap', new Map())
    store.commit('indexStore/setZoomLevels', [])
    store.commit('chunkStore/setBinWidth', null)
    store.commit('metaStore/setReadsInFiles', false)

    store.commit('metaStore/setSelectedGene', null)
    store.commit('metaStore/setSelectedSortOrder', 'asc')
    store.commit('metaStore/setSelectedMetadataToColor', 'none')

    this.cleanupChunks()
    this.cleanupColumnInfo()

    // TODO handle what to cleanup in which situations, jump, sort, init, ...?
    // store.commit('indexStore/setZoomLevels', [])
  },

  cleanupOnZoom () {
    this.cleanupChunks()
    this.cleanupColumnInfo()
  },

  cleanupChunks () {
    store.commit('chunkStore/setCachedChunks', {})
    store.commit('chunkStore/setCachedBins', 0)
    store.commit('chunkStore/setGraphTracks', new Map())
    store.commit('chunkStore/setRawGraphTracks', new Map())
    store.commit('chunkStore/setReadTracks', {})
    store.commit('metaStore/setSortingTable', {})
    store.commit('metaStore/setNeededLeftMargin', 0)
  },

  cleanupColumnInfo () {
    store.commit('metaStore/setLinkInfo', {})
    store.commit('metaStore/setColumnInfo', {})
    store.commit('metaStore/setLinkColumns', [])
    store.commit('metaStore/setMaxLinkHeight', 0)
    linkHeights = []
  },

  cleanupCache () {
    // clear link info
    this.cleanupColumnInfo()

    // clear position info
    store.commit('chunkStore/setCurrentFirstColumn', null)
    store.commit('chunkStore/setCurrentLastColumn', 0)
    store.commit('chunkStore/setCurrentFirstBin', null)
    store.commit('chunkStore/setCurrentLastBin', 0)
    // store.commit('chunkStore/setCurrentZoomLevel', null)

    // clear highlighting - NOTE: Do NOT clear actual selectedHighlight
    store.commit('graphStore/setIsHighlighted', false)

    // clear gene info
    store.commit('metaStore/setSelectedGene', null)

    this.removeHighlight()
  }
}

export default Graph
