import { IndexStoreState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

const namespaced = true

const state: IndexStoreState = {
  zoomLevels: [],
  pathMap: new Map()
}

const mutations: MutationTree<IndexStoreState> = {
  setZoomLevels (state, payload) {
    state.zoomLevels = payload
  },
  setPathMap (state, payload) {
    state.pathMap = payload
  }
}

const actions: ActionTree<IndexStoreState, RootState> = {}

const getters: GetterTree<IndexStoreState, RootState> = {}

export const IndexStore: Module<IndexStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
