import Vue from 'vue'
import { MetaStoreState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import * as PIXI from 'pixi.js'

const namespaced = true

const state: MetaStoreState = {
  sortingTable: {},
  blankRowsBeforeReads: 1,
  readsInFiles: false,
  readsetNames: [],
  neededLeftMargin: 0,
  covFraction: 0.05,
  lastCovFraction: 0,
  metaData: [],
  metaDataCategories: [],
  metaDataColors: [],
  geneInfo: [],
  colorLookupTable: {},
  selectedSortOption: '',
  selectedSortOrder: 'asc',
  selectedPath: null,
  selectedPathPosition: null,
  selectedPanPosition: null,
  selectedGene: null,
  linkInfo: {},
  columnInfo: {},
  linkColumns: [],
  maxLinkHeight: 0,
  drawInversions: true,
  drawDuplications: true,
  drawLinks: true,
  selectedLinkType: 'id',
  selectedMetadataToColor: 'none',
  drawMetaData: false,
  rightClickData: { show: false, rawCoords: new PIXI.Point(), x: 0, y: 0, cellHasGene: false },
  leftClickData: { rawCoords: new PIXI.Point(), cellHasGene: false },
  drawCellMargin: false,
  denseView: false,
  tooltipShown: false
}

const mutations: MutationTree<MetaStoreState> = {
  setSortingTable (state, payload) {
    state.sortingTable = payload
  },

  addSortingTableEntry (state, payload) {
    if (!(payload in state.sortingTable)) {
      Vue.set(state.sortingTable, payload, 0)
    }
  },

  setReadsInFiles (state, payload) {
    state.readsInFiles = payload
  },

  setReadsetNames (state, payload) {
    state.readsetNames = payload
  },

  setNeededLeftMargin (state, payload) {
    state.neededLeftMargin = payload
  },

  setCovFraction (state, payload) {
    state.covFraction = payload
  },

  setLastCovFraction (state, payload) {
    state.lastCovFraction = payload
  },

  setMetaData (state, payload) {
    state.metaData = payload
  },

  setMetaDataCategories (state, payload) {
    state.metaDataCategories = payload
  },

  setMetaDataColors (state, payload) {
    state.metaDataColors = payload
  },

  setGeneInfo (state, payload) {
    state.geneInfo = payload
  },

  setColorLookupTable (state, payload) {
    state.colorLookupTable = payload
  },

  setSelectedPath (state, payload) {
    state.selectedPath = payload
  },

  setSelectedPathPosition (state, payload) {
    state.selectedPathPosition = payload
  },

  setSelectedPanPosition (state, payload) {
    state.selectedPanPosition = payload
  },

  setSelectedGene (state, payload) {
    state.selectedGene = payload
  },

  setSelectedSortOption (state, payload) {
    state.selectedSortOption = payload
  },

  setSelectedSortOrder (state, payload) {
    state.selectedSortOrder = payload
  },

  setLinkInfo (state, payload) {
    state.linkInfo = payload
  },

  addLinkInfo (state, payload) {
    Vue.set(state.linkInfo, payload.linkId, payload.linkInfo)
  },

  setColumnInfo (state, payload) {
    state.columnInfo = payload
  },

  addColumnInfo (state, payload) {
    Vue.set(state.columnInfo, payload.column, payload.columnInfo)
  },

  setLinkColumns (state, payload) {
    state.linkColumns = payload
  },

  addLinkColumn (state, payload) {
    state.linkColumns.push(payload)
  },

  setMaxLinkHeight (state, payload) {
    state.maxLinkHeight = payload
  },

  setDrawLinks (state, payload) {
    state.drawLinks = payload
  },

  setDrawInversions (state, payload) {
    state.drawInversions = payload
  },

  setDrawDuplications (state, payload) {
    state.drawDuplications = payload
  },

  setSelectedLinkType (state, payload) {
    state.selectedLinkType = payload
  },

  setSelectedMetadataToColor (state, payload) {
    state.selectedMetadataToColor = payload
  },

  setDrawMetaData (state, payload) {
    state.drawMetaData = payload
  },

  setRightClickData (state, payload) {
    state.rightClickData = payload
  },

  setLeftClickData (state, payload) {
    state.leftClickData = payload
  },

  setDrawCellMargin (state, payload) {
    state.drawCellMargin = payload
  },

  setDenseView (state, payload) {
    state.denseView = payload
  },

  setTooltipShown (state, payload) {
    state.tooltipShown = payload
  }
}

const actions: ActionTree<MetaStoreState, RootState> = {}

const getters: GetterTree<MetaStoreState, RootState> = {
  getAvailableSortOptions: (state) => {
    // Default sort options
    let sortOptions = ['id', 'name']

    if (state.metaDataCategories.length > 0) {
      sortOptions = sortOptions.concat(state.metaDataCategories)
    }

    return sortOptions
  }
}

export const MetaStore: Module<MetaStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
