import store from '@/store'
import Papa from 'papaparse'
import * as PIXI from 'pixi.js'
import PixiConfig from '@/graph/Config'
import chroma, { Scale } from 'chroma-js'
import SortingUtils from '@/utils/SortingUtils'
import { Chunk, GraphPath, ZoomLevel } from '@/types/Types'
import { LocalApiService } from '@/api/ApiService'
import PixiUtils from '../graph/Graph'

const LocalFileProvider = {
  removeInvalidCharsAndTrim (input: string) {
    return input.replace(/[^\w.-]/g, '').substring(0, 30)
  },

  checkAndLoadIndexFile: function (path: string) {
    return new Promise<void>((resolve, reject) => {
      if (store.state.indexStore.pathMap.size === 0) {
        LocalApiService(path + '/index.json').then((res) => {
          if (!('zoom_levels' in res.data)) {
            reject(new Error('No zoom levels in index file'))
          }
          store.commit('indexStore/setZoomLevels', res.data.zoom_levels)
          // sort zoom levels
          res.data.zoom_levels = SortingUtils.sortZoomLevels(res.data.zoom_levels)

          // if we include the following line, then also consistently on the path names in the chunk files
          // in addition, pggb conventions have a lot of special chars, so we skip it for now
          // pathNames.map((item: any) => LocalFileProvider.removeInvalidCharsAndTrim(item))

          if (!('path_names' in res.data)) {
            reject(new Error('No path names in index file'))
          }
          const pathMap = new Map()
          res.data.path_names.forEach((pathname: string, idx: number) => {
            pathMap.set(pathname, idx)
          })
          store.commit('indexStore/setPathMap', pathMap)
          // store.commit('metaStore/setSelectedSortOption', 'id')

          // If binwidth is not already set, or the default binwidth does not exist: use the lowest binwidth in the data structure
          if (!store.state.chunkStore.binWidth) {
            if (Object.keys(res.data.zoom_levels) != null && Object.keys(res.data.zoom_levels).length > 0) {
              store.commit('chunkStore/setBinWidth', Number(res.data.zoom_levels[0].level))
              store.commit('chunkStore/setCurrentMaxBin', res.data.zoom_levels[0].num_bins)
            }
          } else {
            store.commit('chunkStore/setCurrentMaxBin', this.getZoomLevelObj(store.state.chunkStore.binWidth).num_bins)
          }

          if (store.state.chunkStore.binWidth >= PixiConfig.denseViewCutoff) {
            store.commit('metaStore/setDenseView', true)
          }

          if ('readset_names' in res.data) {
            store.commit('metaStore/setReadsInFiles', true)
            store.commit('metaStore/setReadsetNames', res.data.readset_names)
          }

          resolve()
        }).catch((error) => {
          reject(error)
        })
      }
    })
  },

  loadGeneFile (path: string, file: string) {
    return new Promise<void>((resolve, reject) => {
      LocalApiService(path + '/' + file).then(res => {
        const geneFileRaw = Papa.parse(res.data, { header: true, skipEmptyLines: true })
        const geneFile = geneFileRaw.data
        // TODO: Types?
        const geneInfo: Record<string, any> = {}
        geneFile.forEach((value: any) => {
          const { geneID, ...valueData } = value
          geneInfo[geneID] = valueData
        })
        store.commit('metaStore/setGeneInfo', geneInfo)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  loadMetaData (path: string, file: string) {
    return new Promise<void>((resolve, reject) => {
      LocalApiService(path + '/' + file).then(res => {
        const metadataFileRawParsed = Papa.parse(res.data, { header: true, skipEmptyLines: true, dynamicTyping: true })
        const metadataFile = metadataFileRawParsed.data
        const metadataObject: Record<string, any> = {}
        const metaInfoColumnNames = metadataFileRawParsed.meta.fields as Array<string>

        if (!metaInfoColumnNames) {
          reject(new Error('no meta info columns given'))
          return
        }

        // Get first element to save meta infos
        metaInfoColumnNames.splice(metaInfoColumnNames.indexOf('PathName'), 1)
        const metaInfoColumnNamesCount = metaInfoColumnNames.length

        metadataFile.forEach((value: any) => {
          const { PathName, ...valueData } = value
          metadataObject[PathName] = valueData
        })

        const metaMapping: Record<string, any> = {}
        const colorPaletteSize = chroma.brewer.Set3.length
        for (let i = 0; i < metaInfoColumnNamesCount; i++) {
          const uniqMetadata = [...new Set(metadataFile.map((x: any) => x[metaInfoColumnNames[i]]))]
          const onlyNumerical = !uniqMetadata.some(isNaN)
          let maxValue = -1
          let minValue = -1
          if (onlyNumerical) {
            minValue = Math.min(...uniqMetadata)
            maxValue = Math.max(...uniqMetadata)
          }
          // 2 neighboring colors in the brewer 'paired' palette are quite similar, therefore Set3 is used for now
          const currentColor = chroma(chroma.brewer.Set3[i % colorPaletteSize])
          let colorPaletteScale: Scale | null = null
          let colorPaletteArray: string[] | null = null
          if (onlyNumerical) {
            colorPaletteScale = chroma.scale([currentColor.brighten(1), currentColor.darken(2)])
          } else {
            colorPaletteArray = chroma.scale([currentColor.brighten(1), currentColor.darken(2)]).colors(uniqMetadata.length)
          }
          metaMapping[metaInfoColumnNames[i]] = {}

          for (let k = 0; k < uniqMetadata.length; k++) {
            if (uniqMetadata[k] === '' || uniqMetadata[k] === undefined || uniqMetadata[k] === 'NA') {
              metaMapping[metaInfoColumnNames[i]][uniqMetadata[k]] = '0xFFFFFF' // white for missing data
            } else if (onlyNumerical && !!colorPaletteScale) {
              const newVal = (uniqMetadata[k] - minValue) / (maxValue - minValue)
              metaMapping[metaInfoColumnNames[i]][uniqMetadata[k]] = colorPaletteScale(newVal).hex().replace('#', '0x')
            } else if (colorPaletteArray) {
              metaMapping[metaInfoColumnNames[i]][uniqMetadata[k]] = colorPaletteArray[k].replace('#', '0x')
            }
          }
        }

        let maxSize = metaInfoColumnNamesCount * (PixiConfig.cellWidth + PixiConfig.cellMargin)
        maxSize += store.state.metaStore.neededLeftMargin

        store.commit('metaStore/setNeededLeftMargin', Math.ceil(maxSize))
        // contains the complete metadata info
        store.commit('metaStore/setMetaData', metadataObject)
        // contains the headers / available metadata

        // Push 'name' to be able to sort by name with meta sorting arrows
        metaInfoColumnNames.push('name')
        // Create metaColors array
        // Replace 'name' with 'none' to be able to reset meta color to default in the dropdown menu
        const metaColors = metaInfoColumnNames.slice()
        metaColors[metaColors.length - 1] = 'none'
        store.commit('metaStore/setMetaDataCategories', metaInfoColumnNames)
        store.commit('metaStore/setMetaDataColors', metaColors)
        store.commit('metaStore/setColorLookupTable', metaMapping)

        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  // This is specific for loading while using JSON files, needs different logic for API calls
  initMetadataFromFile () {
    return new Promise<void>((resolve, reject) => {
      const path = store.state.chunkStore.path

      if (path == null) {
        reject(new Error('path not found!'))
      }

      const readNames = store.state.metaStore.readsetNames
      if (store.state.metaStore.readsInFiles) {
        // TODO for later:
        // readNames.map((item: string) => LocalFileProvider.removeInvalidCharsAndTrim(item))

        store.commit('metaStore/setReadsetNames', readNames)
      }

      // // Calculate the maximum width of the text
      // // The function removeInvalidCharsAndTrim would trim this to 30 chars on import
      const style = PixiConfig.getGenomeNameTextStyle()

      let maxSize = 0

      // iterate over all possible paths; then we do not have to reload this function if different paths are
      // viewed in different pangenome locations
      for (const k of store.state.indexStore.pathMap.keys()) {
        // Pixi.js should define the type of the new signature
        const textMetric: PIXI.TextMetrics = PIXI.TextMetrics.measureText(k, style)
        if (maxSize < textMetric.width) {
          maxSize = textMetric.width
        }
      }
      if (store.state.metaStore.readsInFiles) {
        for (let i = 0; i < readNames.length; i++) {
          const textMetric: PIXI.TextMetrics = PIXI.TextMetrics.measureText(store.state.metaStore.readsetNames[i], style)

          if (maxSize < textMetric.width) {
            maxSize = textMetric.width
          }
        }
      }

      store.commit('metaStore/setNeededLeftMargin', Math.ceil(maxSize))

      this.loadMetaData(path, 'metadata.csv').then(() => {
        store.commit('metaStore/setDrawMetaData', true)
      }).catch(() => {
        // If metadata.csv is not provided, we can only sort by name
        store.commit('metaStore/setMetaDataCategories', ['name'])
      }).finally(() => {
        this.loadGeneFile(path + '/bin' + store.state.chunkStore.binWidth, PixiConfig.geneFileName).catch((error) => {
          store.commit('metaStore/setGeneInfo', [])
          reject(error)
        })

        resolve()
      })
    })
  },

  fillPathsToDraw (chunkID: number) {
    const chunk = store.state.chunkStore.cachedChunks[chunkID]
    for (const p of Object.keys(chunk.paths)) {
      store.commit('metaStore/addSortingTableEntry', p)
      store.commit('chunkStore/incPathsToDraw', { id: p, val: 'cov_bins' in chunk.paths[p] ? chunk.paths[p].cov_bins : chunk.nrBins })
    }
  },

  loadTracks (chunks: Array<number>, extend = false) {
    return new Promise<void>((resolve, reject) => {
      const path = store.state.chunkStore.path
      const binWidth = store.state.chunkStore.binWidth
      const promises = []

      // TODO: it might be easier to delete pathsToDraw and fill this up from scratch again, rather than delete chunks not needed anymore one by one !
      //       since it's likely that the new chunks are disjunct from the cached set of chunks
      if (!extend) {
        // delete the cached chunks that are not in chunks, but don't touch cachedChunks when additional chunks should be loaded
        let cachedChunk: any // the next for-loop doesn't accept <number> as iterator...
        for (cachedChunk in store.state.chunkStore.cachedChunks) {
          if (!chunks.includes(+cachedChunk)) {
            // reduce path counts by the chunkObj counts that is not in the 'chunks' array:
            const chunkObj = store.state.chunkStore.cachedChunks[cachedChunk]
            for (const p of Object.keys(store.state.chunkStore.cachedChunks[cachedChunk].paths)) {
              store.commit('chunkStore/deleteGraphTrack', { id: p, val: 'cov_bins' in chunkObj.paths[p] ? chunkObj.paths[p].cov_bins : chunkObj.nrBins })
              store.commit('chunkStore/deleteRawGraphTrack', { id: p, val: 'cov_bins' in chunkObj.paths[p] ? chunkObj.paths[p].cov_bins : chunkObj.nrBins })
            }
            store.commit('chunkStore/decrCachedBins', chunkObj.nrBins)
            store.commit('chunkStore/deleteCachedChunks', cachedChunk)
          }
        }
      }

      for (const chunk of chunks) {
        if (chunk in store.state.chunkStore.cachedChunks) {
          // TODO: simplify this, we don't need to check if the chunk is cached, since we only call this function if it is cached
          // console.log('[loadAndStorePaths] file cached:', chunk)
        } else {
          promises.push(LocalApiService(path + '/bin' + binWidth + '/' + LocalFileProvider.getFileName(chunk)).then(result => {
            if (result.data) {
              this.preprocessChunk(result.data, chunk)
            }
          }).catch((error) => {
            reject(error)
          }))

          if (store.state.metaStore.readsInFiles) {
            promises.push(LocalApiService(path + '/bin' + binWidth + '/' + LocalFileProvider.getReadsFileName(chunk)).then(result => {
              if (result.data.read_paths) {
                store.commit('chunkStore/addReadTracks', { id: chunk, data: result.data.read_paths })
              }
            }).catch((error) => {
              reject(error)
            }))
          }
        }
      }

      Promise.allSettled(promises).then(() => {
        PixiUtils.updateCachedChunksCoords()
        SortingUtils.sortTracks()
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  preprocessChunk (data: any, chunkID: number) {
    return new Promise<void>((resolve) => {
      const values: Chunk = { id: chunkID }

      let seq = data.sequence
      if (seq) seq = seq.replace(/(\r\n|\n|\r)/gm, '')

      const nrBins = data.last_bin - data.first_bin + 1

      const paths: Record<string, GraphPath> = {}
      data.graph_paths.forEach((obj: GraphPath) => {
        const pathname = obj.path_name
        paths[pathname] = obj

        store.commit('metaStore/addSortingTableEntry', pathname)
        store.commit('chunkStore/addGraphTrack', { id: pathname, val: 'cov_bins' in obj ? obj.cov_bins : nrBins })
        store.commit('chunkStore/addRawGraphTrack', { id: pathname, val: 'cov_bins' in obj ? obj.cov_bins : nrBins })
      })

      values.data = {
        firstBin: data.first_bin,
        firstCol: data.first_bin + data.xoffsets[0],
        lastBin: data.last_bin,
        lastCol: data.max_x,
        nrBins,
        nrCols: nrBins + data.xoffsets[nrBins - 1] - data.xoffsets[0] + 1,
        paths,
        xoffsets: data.xoffsets,
        sequence: seq
      }

      store.commit('chunkStore/addCachedBins', nrBins)
      store.commit('chunkStore/addCachedChunks', values)
      resolve()
    })
  },

  doesZoomLevelExist (binWidth: number, zoomLevels: Array<ZoomLevel> | null = null) {
    if (!zoomLevels) {
      zoomLevels = this.getAvailableBinWidths()
    }
    for (const zoomLevel of zoomLevels) {
      if (zoomLevel.level === binWidth) {
        return true
      }
    }

    return false
  },

  getAvailableBinWidths (): Array<ZoomLevel> {
    if (!store.state.indexStore.zoomLevels == null) {
      return []
    }

    // Assuming we have numbers here...
    return store.state.indexStore.zoomLevels.map((item: ZoomLevel) => (item.level))
  },

  getAvailableSortOptions () {
    const sortOptions = ['id', 'name']
    return sortOptions
  },

  getZoomLevelObj (binWidth: number) {
    for (const zoomLevel of store.state.indexStore.zoomLevels) {
      if (zoomLevel.level === binWidth) {
        return zoomLevel
      }
    }

    return null
  },

  getLowerZoomLevel (binWidth: number) {
    const zoomLevels = store.state.indexStore.zoomLevels
    for (let i = 0; i < zoomLevels.length; ++i) {
      if (zoomLevels[i].level === binWidth && i !== 0) {
        return zoomLevels[i - 1].level
      }
    }

    return null
  },

  getHigherZoomLevel (binWidth: number) {
    const zoomLevels = store.state.indexStore.zoomLevels
    for (let i = 0; i < zoomLevels.length; ++i) {
      if (zoomLevels[i].level === binWidth && i !== zoomLevels.length - 1) {
        return zoomLevels[i + 1].level
      }
    }

    return null
  },

  getAvailableDatasets () {
    if (!Array.isArray(store.state.chunkStore.datasetList) || !store.state.chunkStore.datasetList.length) {
      this.loadDatasetFile(store.state.chunkStore.datasetFolder, 'datasets.csv').then(() => {
        return store.state.chunkStore.datasetList
      })
    } else {
      return store.state.chunkStore.datasetList
    }
  },

  loadDatasetFile (path: string, file: string) {
    return new Promise<void>((resolve, reject) => {
      LocalApiService(path + '/' + file).then(res => {
        const datasetListRawParsed = Papa.parse(res.data, { header: true, skipEmptyLines: true })
        const datasetList = datasetListRawParsed.data

        store.commit('chunkStore/setDatasetList', datasetList)

        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  getNextFilename (side: string, focalBin = 0) {
    const zoomLevel = LocalFileProvider.getZoomLevelObj(store.state.chunkStore.binWidth)

    if (focalBin === 0) {
      if (side === 'right') focalBin = store.state.chunkStore.currentLastBin
      else focalBin = store.state.chunkStore.currentFirstBin
    }

    if (side === 'right') {
      // check if we are currently at the last file!
      if (focalBin >= zoomLevel.num_bins) {
        return 1
      }

      const lastFileId = Math.floor(focalBin / zoomLevel.bins_per_file) - 1
      return this.getFileName(lastFileId + 1)
    } else {
      // Do not load left if we're at the start
      if (focalBin <= 1) {
        return null
      }

      const lastFileId = Math.ceil(focalBin / zoomLevel.bins_per_file) - 1
      if ((lastFileId - 1) === 0) {
        return null
      }
      return this.getFileName(lastFileId - 1)
    }
  },

  getFileName (chunk: number) {
    return 'chunk.' + chunk + '.json'
  },

  getReadsFileName (chunkId: number) {
    return 'reads.' + this.getFileName(chunkId)
  },

  getFileIndexForBinPos (bin: number) {
    const zoomLevel = LocalFileProvider.getZoomLevelObj(store.state.chunkStore.binWidth)
    const binnr = (bin === 0 ? 0 : bin - 1)

    return Math.floor(binnr / zoomLevel.bins_per_file)
  },

  getLastFileIndex () {
    const zoomlevel = LocalFileProvider.getZoomLevelObj(store.state.chunkStore.binWidth)

    return Math.floor((zoomlevel.num_bins - 1) / zoomlevel.bins_per_file)
  }

}

export default LocalFileProvider
