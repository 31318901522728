
import { Vue, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Graph from '@/graph/Graph'
import * as PIXI from 'pixi.js'
import Chip from './Chip.vue'

@Component({
  components: {
    Chip
  }
})
export default class GeneMenu extends Vue {
  geneName: string | null = ''
  geneStrand: string | null = ''
  chipVisible: boolean | null = false
  panel = [0, 1]

  get coords () {
    return this.$store.state.uiStore.geneCoords
  }

  @Watch('coords')
  onCoordsChanged (value: PIXI.Point) {
    const geneInfos = Graph.getGeneInfos(value.x, value.y)
    this.geneName = geneInfos.name
    this.geneStrand = geneInfos.strand
  }

  copyToClipboard (copyText: string | null) {
    if (copyText !== null) {
      navigator.clipboard.writeText(copyText)
      this.chipVisible = !this.chipVisible
    }
  }
}
