
import { Component, Vue } from 'vue-property-decorator'
import LocalFileProvider from '@/services/LocalFileProvider'

@Component
export default class Dashboard extends Vue {
  get selectedDataset () {
    return this.$store.state.chunkStore.selectedDataset
  }

  set selectedDataset (value) {
    this.$store.commit('chunkStore/setDataset', value)
  }

  get dataSets () {
    return LocalFileProvider.getAvailableDatasets()
  }

  onDatasetChange () {
    this.$router.push({ name: 'Graph', params: { dataset: this.selectedDataset } })
  }
}
