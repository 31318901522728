
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Placeholder extends Vue {
  get visible () {
    return this.$store.state.graphStore.placeholderVisible
  }

  openTrackMenu () {
    this.$store.commit('uiStore/setSidebarState', { enabled: true, target: 'TrackMenu' })
  }
}
