import Vue from 'vue'
import Router from 'vue-router'

// Views
import Dashboard from '@/views/Dashboard.vue'
import Graph from '@/views/Graph.vue'

Vue.use(Router)

// We retreive the query (when pasted into the address bar) to directly load the shared view
declare module 'vue/types/vue' {
  interface Vue {
    $query: Record<string, string>
  }
}

Vue.mixin({
  beforeCreate () {
    const query = Object.fromEntries(new URLSearchParams(window.location.search))
    this.$query = Vue.observable(query)
  }
})

// Router init
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/dashboard'
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/graph',
      name: 'Graph',
      component: Graph
    }
    // {
    //   path: '*',
    //   name: '404',
    //   component: Login
    // }
  ]
})

export default router
