
import { Component, Vue } from 'vue-property-decorator'
import SnapshotMenu from '@/components/SnapshotMenu.vue'

@Component({
  components: {
    SnapshotMenu
  }
})
export default class Appbar extends Vue {
  // ------------------------------------------------
  // Reloading
  // ------------------------------------------------
  reload () {
    this.$router.push({ name: 'home' })
    // Clear query parameters from URL
    history.replaceState(null, '', window.location.pathname)
    // Reload page
    window.location.reload()
  }

  // ------------------------------------------------
  // Legend
  // ------------------------------------------------
  toggleLegend () {
    this.$store.commit('uiStore/toggleLegend')
  }

  // ------------------------------------------------
  // Documentation
  // ------------------------------------------------
  get platformHelpUrl () {
    return process.env.VUE_APP_HELP_URL
  }

  goToHelp () {
    window.open(this.platformHelpUrl) // , '_blank')
  }
}
