import Config from '@/graph/Config'
import * as PIXI from 'pixi.js'
import store from '@/store'

export default class SortingTools {
  // Meta sort tooltip target
  // Needed for tooltip to work with sorting arrows
  public tooltipTarget: number

  // Meta container
  private metaContainer: PIXI.Container

  // Create arrows
  private arrows: { arrow: PIXI.Graphics, active: boolean }[]

  // If an arrow is active, default mode is set to false
  private defaultMode: boolean

  constructor (metaContainer: PIXI.Container) {
    this.metaContainer = metaContainer
    this.tooltipTarget = 0
    this.arrows = []
    this.defaultMode = true

    // Check if one arrow is already active
    for (let i = 0; i < store.state.metaStore.metaDataCategories.length; i++) {
      if (store.state.metaStore.metaDataCategories[i] === store.state.metaStore.selectedSortOption) {
        this.defaultMode = false
      }
    }

    // Iterate over all metadata categories and draw an arrow for each one
    for (let i = 0; i < store.state.metaStore.metaDataCategories.length; i++) {
      // Init arrow
      let arrow: PIXI.Graphics = new PIXI.Graphics()

      // If selected sort option is the same as current metadata category so the matching arrow is active
      // Active arrow (yellow)
      if (store.state.metaStore.metaDataCategories[i] === store.state.metaStore.selectedSortOption) {
        arrow = this.drawArrow(0xffd700, true)
        arrow.alpha = 1
      } else {
        // Inactive arrow (grey)
        arrow = this.drawArrow(0x808080, false)
        // If no arrow is active then we display the first arrow (default arrow)
        if (this.defaultMode && i === 0) {
          arrow.alpha = 1
        } else {
          arrow.alpha = 0
        }
      }

      // Flip arrow if sortOrder is inverted
      if (store.state.metaStore.selectedSortOrder === 'desc') {
        arrow.rotation += Math.PI
      }

      // Create button
      const button = new PIXI.Container()
      const offsetX = i * (Config.cellWidth + 1)
      button.position.set(offsetX, 0)
      button.interactive = true
      button.cursor = 'pointer'
      // Assign an index to the button
      button.name = i.toString()
      button.on('mouseover', this.showArrow.bind(this))
      button.on('mouseout', this.hideArrow.bind(this))
      button.on('click', this.sort.bind(this))
      // Add overlay
      const overlay = new PIXI.Graphics()
      overlay.beginFill(0x0000ff, 1)
      overlay.drawRect(0, 0, Config.cellWidth, Config.cellHeight)
      overlay.endFill()
      overlay.alpha = 0

      // Add childs
      button.addChild(arrow)
      button.addChild(overlay)
      this.metaContainer.addChild(button)
    }
  }

  drawArrow (color: number, active: boolean) {
    const arrow = new PIXI.Graphics()

    // Draw arrow
    arrow.beginFill(color)
    arrow.lineTo(10, 0)
    arrow.lineTo(5, 5)
    arrow.closePath()
    arrow.endFill()

    // Center the anchor point
    const bounds = arrow.getBounds()
    arrow.pivot.set(bounds.width / 2, bounds.height / 2)
    arrow.position.set(bounds.width / 2, bounds.height / 2)

    arrow.scale.set(0.8, 0.8)
    // Push arrow to arrows array
    this.arrows.push({ arrow: arrow, active: active })
    return arrow
  }

  // Sort on click
  sort (this: any, e: any) {
    // Get index
    const index = e.currentTarget.name
    // Get metadata category
    const category = store.state.metaStore.metaDataCategories[index]

    let sortOrder: string = store.state.metaStore.selectedSortOrder
    if (category === store.state.metaStore.selectedSortOption) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }
    }

    // Update sorting option
    store.commit('metaStore/setSelectedSortOption', category)
    // Update sorting order
    store.commit('metaStore/setSelectedSortOrder', sortOrder)
  }

  // Show arrow on mouseover
  showArrow (this: any, e: any) {
    // Get index
    const index = e.currentTarget.name
    // Show arrow
    this.arrows[index].arrow.alpha = 1
    // Set meta sorting target
    this.tooltipTarget = index
  }

  // Hide arrow on mouseout
  hideArrow (this: any, e: any) {
    // Get index
    const index = e.currentTarget.name
    // Hide arrow if not active
    this.arrows[index].arrow.alpha = this.arrows[index].active ? 1 : 0
    // If default mode is active, show default arrow
    if (index === '0' && this.defaultMode) {
      this.arrows[index].arrow.alpha = 1
    }
  }
}
