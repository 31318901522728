import store from '@/store'
import { ZoomLevel } from '@/types/Types'

const SortingUtils = {
  byMetaData () {
    return function (a: any, b: any) {
      if (!(a[0] in store.state.metaStore.metaData)) return -1
      if (!(b[0] in store.state.metaStore.metaData)) return 1

      const val1 = store.state.metaStore.metaData[a[0]][store.state.metaStore.selectedSortOption]
      const val2 = store.state.metaStore.metaData[b[0]][store.state.metaStore.selectedSortOption]
      if (typeof val1 === 'number') {
        return val1 - val2
      } else {
        return val1 < val2 ? -1 : (val1 > val2 ? 1 : 0)
      }
    }
  },

  byColumn () {
    return function (a: any, b: any) {
      return store.state.metaStore.sortingTable[b[0]] - store.state.metaStore.sortingTable[a[0]]
    }
  },

  sortTracks () {
    const sortKey = store.state.metaStore.selectedSortOption
    const metaDataCategories = store.state.metaStore.metaDataCategories
    let graphTracks = new Map()

    if (store.state.metaStore.selectedSortOption === 'id') {
      graphTracks = new Map([...store.state.chunkStore.graphTracks.entries()].sort((a, b) =>
        store.state.indexStore.pathMap.get(a[0]) - store.state.indexStore.pathMap.get(b[0])
      ))
    } else if (store.state.metaStore.selectedSortOption === 'name') {
      graphTracks = new Map([...store.state.chunkStore.graphTracks.entries()].sort((a, b) =>
        (a > b ? 1 : (a === b ? 0 : -1))
      ))
    } else if (sortKey === 'column') {
      graphTracks = new Map([...store.state.chunkStore.graphTracks.entries()].sort(this.byColumn()))
    } else if (Array.isArray(metaDataCategories) || metaDataCategories.length) {
      graphTracks = new Map([...store.state.chunkStore.graphTracks.entries()].sort(this.byMetaData()))
    }

    if (store.state.metaStore.selectedSortOrder === 'desc') {
      graphTracks = new Map([...graphTracks].reverse())
    }

    store.commit('chunkStore/setGraphTracks', graphTracks)
  },

  resetSort () {
    store.commit('metaStore/setSortingTable', [])
    store.commit('metaStore/setSelectedSortOption', 'id')
    store.commit('metaStore/setDrawMetaData', false)
    store.commit('metaStore/setMetaDataCategories', [])
  },

  sortZoomLevels (zoomLevels: Array<ZoomLevel>) {
    zoomLevels.sort(function (a, b) {
      return a.level - b.level
    })
    return zoomLevels
  }
}

export default SortingUtils
