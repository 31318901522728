
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Loader extends Vue {
  get loading () {
    return this.$store.state.graphStore.loading
  }

  set loading (value) {
    this.$store.commit('graphStore/setLoading', value)
  }
}
