import * as PIXI from 'pixi.js'

export default class Loader extends PIXI.Container {
  protected _width: number
  protected _height: number

  private background: PIXI.Graphics
  private circleContainer: PIXI.Graphics
  private circle: PIXI.Graphics

  constructor (width: number, height: number) {
    super()

    this._width = width
    this._height = height

    this.background = new PIXI.Graphics()
    this.background.beginFill(0xffffff, 0.4)
    this.background.drawRect(0, 0, this.width, this.height)
    this.background.endFill()

    this.circleContainer = new PIXI.Graphics()
    this.circleContainer.beginFill(0xB82837)
    this.circleContainer.drawCircle(0, 0, 0)
    this.circleContainer.endFill()
    this.circleContainer.x = this.width / 2
    this.circleContainer.y = this.height / 2

    this.circle = new PIXI.Graphics()
    this.circle.beginFill(0xB82837)
    this.circle.drawCircle(-15, -15, 3)
    this.circle.endFill()

    this.addChild(this.background)
    this.addChild(this.circleContainer)
    this.circleContainer.addChild(this.circle)

    // Hide by default
    this.hide()
  }

  get width () {
    return this._width
  }

  get height () {
    return this._height
  }

  show () {
    this.alpha = 1
  }

  hide () {
    this.alpha = 0
  }

  update () {
    this.circleContainer.rotation += 0.25
  }
}
