import { UIStoreState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import store from '@/store'
import * as PIXI from 'pixi.js'

const namespaced = true

const state: UIStoreState = {
  // Slider
  sliderPosition: [],
  // SnapshotMenu
  snapshotMenuEnabled: false,
  // Legend
  legendVisible: false,
  // TrackMenu
  disabledGraphTracks: [],
  disabledReadTracks: [],
  // GeneMenu
  geneCoords: null,
  // SideBar
  sidebarState: { enabled: false, target: null }
}

const mutations: MutationTree<UIStoreState> = {
  // ------------------------------------------------
  // Slider
  // ------------------------------------------------
  setSliderPosition (state, payload) {
    if (payload[0] == null) {
      payload[0] = (state.sliderPosition[0] - 1) * store.state.chunkStore.binWidth
    } else if (payload[0] === 0) {
      payload[0] = 1
    }

    if (payload[1] == null) {
      payload[1] = state.sliderPosition[1] * store.state.chunkStore.binWidth
    }

    if (payload !== state.sliderPosition) {
      state.sliderPosition = payload
    }
  },
  // ------------------------------------------------
  // SnapshotMenu
  // ------------------------------------------------
  setSnapshotMenuEnabled (state: UIStoreState, payload: boolean) {
    state.snapshotMenuEnabled = payload
  },

  // ------------------------------------------------
  // Legend
  // ------------------------------------------------
  toggleLegend (state: UIStoreState, payload: boolean) {
    state.legendVisible = payload === undefined ? !state.legendVisible : payload
  },

  setLegendVisible (state: UIStoreState, payload: boolean) {
    state.legendVisible = payload
  },

  // ------------------------------------------------
  // SideBar
  // ------------------------------------------------
  setSidebarState (state: UIStoreState, payload: {enabled: boolean, target: string | null}) {
    state.sidebarState = payload
  },

  // ------------------------------------------------
  // TrackMenu
  // ------------------------------------------------
  setDisabledGraphTracks (state: UIStoreState, payload: Array<string>) {
    state.disabledGraphTracks = payload
  },

  setDisabledReadTracks (state: UIStoreState, payload: Array<string>) {
    state.disabledReadTracks = payload
  },

  // ------------------------------------------------
  // GeneMenu
  // ------------------------------------------------
  setGeneCoords (state: UIStoreState, payload: PIXI.Point) {
    state.geneCoords = payload
  }
}

const actions: ActionTree<UIStoreState, RootState> = {
  setSidebarStateAsync ({ commit }, payload: { enabled: boolean, target: string | null }): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        console.log('setSidebarStateAsync', payload)
        commit('setSidebarState', payload)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }
}

const getters: GetterTree<UIStoreState, RootState> = {}

export const UIStore: Module<UIStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
