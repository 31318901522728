import { createPopper, VirtualElement } from '@popperjs/core'
import PixiUtils from '@/graph/Graph'
import store from '@/store'
import PIXI from 'pixi.js'

let virtualElement: any = null
let tooltip: HTMLElement | null = null
let instance: any = null

const TooltipUtils = {

  initGlobalClickableTooltip () {
    virtualElement = {
      getBoundingClientRect: this.generateGetBoundingClientRect()
    }

    tooltip = document.querySelector('#tooltip')

    if (virtualElement && tooltip) {
      instance = createPopper(virtualElement, tooltip, {
        placement: 'right-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [10, 20]
            }
          }
        ]
      })
    }
  },

  hide () {
    if (tooltip) {
      tooltip.style.visibility = 'hidden'
    }
  },

  show () {
    if (tooltip) {
      tooltip.style.visibility = 'visible'
    }
    // PixiUtils.removeHighlight()
  },

  toggleWorldTooltip (event: any, pixiContainer: HTMLElement) {
    const containerId = pixiContainer.getAttribute('id')
    if (containerId && tooltip) {
      const containerRect = document.getElementById(containerId)?.getBoundingClientRect()
      virtualElement.getBoundingClientRect = this.generateGetBoundingClientRect(containerRect?.left + event.screen.x, containerRect?.top + event.screen.y)

      // Get genome if clicked inside the data-view
      const pathName = PixiUtils.getTrackNameByCoordinate(event.world.y)
      // Get cell info for a lnk or a bin
      const cellInfo = PixiUtils.getCellInfo(event.world.x, event.world.y)

      tooltip.innerHTML = ''
      if (!pathName && cellInfo === '') {
        // Hide if nothing would be displayed
        this.hide()
        // PixiUtils.removeHighlight()
      } else {
        if (pathName) {
          tooltip.innerHTML += pathName
        }
        if (cellInfo !== '') {
          if (pathName) tooltip.innerHTML += '</br>'
          tooltip.innerHTML += cellInfo
        }
      }

      instance.update()
    }
  },

  toggleMetaTooltip (coords: { screen: PIXI.Point, world: PIXI.Point }, relativeX: number, pixiContainer: HTMLElement, metaSortTooltipTarget: number) {
    const containerId = pixiContainer.getAttribute('id')
    if (containerId) {
      const containerRect = document.getElementById(containerId)?.getBoundingClientRect()
      if (containerRect) {
        virtualElement.getBoundingClientRect = this.generateGetBoundingClientRect(containerRect?.left + coords.screen.x, containerRect?.top + coords.screen.y)
      }

      // If the mouse is in the sorting area then show the sorting tooltip
      if (coords.world.y <= 10 && tooltip) {
        const category = store.state.metaStore.metaDataCategories[metaSortTooltipTarget]
        tooltip.innerHTML = 'Sort by ' + category
      } else {
        const trackName = PixiUtils.getTrackNameByCoordinate(coords.world.y)
        if (trackName !== '' && tooltip) {
          tooltip.innerHTML = trackName

          if (store.state.metaStore.drawMetaData) {
            const metaColumn = PixiUtils.getMetaColumForCoordinate(relativeX)
            if (metaColumn != null) {
              let metaString = ''
              const metadata = store.state.metaStore.metaData
              if (trackName in metadata) {
                const metaKeys = Object.keys(metadata[trackName])
                if (metaKeys) {
                  metaString = metadata[trackName][metaKeys[metaColumn]]
                  if (metaString !== undefined) {
                    tooltip.innerHTML += '</br>' + metaKeys[metaColumn] + ': ' + metaString
                  }
                }
              }
            }
          }
        } else { // if (tooltip) {
          // tooltip.innerHTML = ''
          // Hide if nothing would be displayed
          this.hide()
        }
      }

      instance.update()
    }
  },

  generateGetBoundingClientRect (x = 0, y = 0) {
    return () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x
    })
  }
}

export default TooltipUtils
