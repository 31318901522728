import Vue from 'vue'
import { ChunkState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

const namespaced = true

const state: ChunkState = {
  path: null,
  datasetList: [],
  datasetFolder: 'test-data',
  binWidth: null,
  selectedDataset: null,
  cachedChunks: {},
  cachedBins: 0,
  sequencePath: null,
  nrChunksToLoad: 0,
  // Current state
  currentMaxBin: 1,
  currentLastColumn: 0,
  currentFirstColumn: null,
  currentFirstBin: null,
  currentLastBin: 0,
  currentZoomLevel: null,
  currentChunk: 0,
  // Tracks
  graphTracks: new Map(),
  rawGraphTracks: new Map(),
  readTracks: {},
  nbTracks: ''
}

const mutations: MutationTree<ChunkState> = {
  setGraphTracks (state, payload) {
    state.graphTracks = payload
  },

  deleteGraphTracks (state, payload) {
    if (state.graphTracks.has(payload)) {
      state.graphTracks.delete(payload)
    }
  },

  addGraphTrack (state, payload) {
    const covdBins = state.graphTracks.get(payload?.id)
    if (covdBins) {
      state.graphTracks.set(payload.id, covdBins + payload.val)
    } else {
      state.graphTracks.set(payload.id, payload.val)
    }
  },

  deleteGraphTrack (state, payload) {
    const covdBins = state.graphTracks.get(payload?.id)
    if (covdBins) {
      state.graphTracks.set(
        payload.id, covdBins - payload.val
      )
      if (covdBins && covdBins <= 0) {
        state.graphTracks.delete(payload.id)
      }
    }
  },

  setRawGraphTracks (state, payload) {
    state.rawGraphTracks = payload
  },

  addRawGraphTrack (state, payload) {
    const covdBins = state.rawGraphTracks.get(payload?.id)
    if (covdBins) {
      state.rawGraphTracks.set(payload.id, covdBins + payload.val)
    } else {
      state.rawGraphTracks.set(payload.id, payload.val)
    }
  },

  deleteRawGraphTrack (state, payload) {
    const covdBins = state.rawGraphTracks.get(payload?.id)
    if (covdBins) {
      state.rawGraphTracks.set(
        payload.id, covdBins - payload.val
      )
      if (covdBins && covdBins <= 0) {
        state.rawGraphTracks.delete(payload.id)
      }
    }
  },

  setReadTracks (state, payload) {
    state.readTracks = payload
  },

  addReadTracks (state, payload) {
    Vue.set(state.readTracks, payload.id, payload.data)
  },

  setNbTracks (state, payload) {
    state.nbTracks = payload
  },

  setDatasetList (state, payload) {
    state.datasetList = payload
  },

  setDatasetFolder (state, payload) {
    state.datasetFolder = payload
  },

  setBinWidth (state, payload) {
    state.binWidth = payload
  },

  setCurrentMaxBin (state, payload) {
    state.currentMaxBin = payload
  },

  setDataset (state, payload) {
    state.selectedDataset = payload
    state.path = state.datasetFolder + '/' + state.selectedDataset
  },

  setCachedChunks (state, payload) {
    state.cachedChunks = payload
  },

  addCachedChunks (state, payload) {
    Vue.set(state.cachedChunks, payload.id, payload.data)
  },

  deleteCachedChunks (state, payload) {
    Vue.delete(state.cachedChunks, payload)
  },

  setCachedBins (state, payload) {
    state.cachedBins = payload
  },

  addCachedBins (state, payload) {
    state.cachedBins = state.cachedBins + payload
  },

  decrCachedBins (state, payload) {
    state.cachedBins = state.cachedBins - payload
  },

  setCurrentFirstBin (state, payload) {
    state.currentFirstBin = payload
  },

  setCurrentLastColumn (state, payload) {
    state.currentLastColumn = payload
  },

  setCurrentFirstColumn (state, payload) {
    state.currentFirstColumn = payload
  },

  setCurrentLastBin (state, payload) {
    state.currentLastBin = payload
  },

  setCurrentZoomLevel (state, payload) {
    state.currentZoomLevel = payload
  },

  setNrChunksToLoad (state, payload) {
    state.nrChunksToLoad = payload
  },

  setCurrentChunk (state, payload) {
    state.currentChunk = payload
  }
}

const actions: ActionTree<ChunkState, RootState> = {}

const getters: GetterTree<ChunkState, RootState> = {
  binWidth (state) {
    return state.binWidth
  }
}

export const ChunkStore: Module<ChunkState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
