
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Chip extends Vue {
  @Prop({ default: '' }) text!: string
  @Prop({ default: false }) visible!: boolean
  @Watch('visible')
  onVisibleChange () {
    this.$el.classList.add('visible')
    setTimeout(() => {
      this.$el.classList.remove('visible')
    }, 1000)
  }
}
