
// Vue
import { Component, Vue, Watch } from 'vue-property-decorator'
import { VForm } from '@/$refs'
import store from '@/store'

// Pixi
import * as PIXI from 'pixi.js'

// Services
import { PanPosService } from '@/services/PanPosService'
import LocalFileProvider from '@/services/LocalFileProvider'

// Utils
import TooltipUtils from '@/utils/TooltipUtils'
import SortingUtils from '@/utils/SortingUtils'
import GeneralUtils from '@/utils/GeneralUtils'

// Graph
import Graph from '@/graph/Graph'
import GraphConfig from '@/graph/Config'
import GraphPlaceholder from '@/graph/components/Placeholder.vue'

// Components
import Appbar from '@/components/AppBar.vue'
import Legend from '@/components/Legend.vue'
import Loader from '@/components/Loader.vue'
import Sidebar from '@/components/Sidebar.vue'

// Xtras
import { Buffer } from 'buffer'

@Component({
  components: {
    Appbar,
    Sidebar,
    Legend,
    Loader,
    GraphPlaceholder
  }
})
export default class GraphView extends Vue {
  isShiftPressed = false
  sliderDisabled = true
  menuOpen = false
  jumpMenuOpen = false
  lastBinWidth: number | null = null
  leftmostBin = 1
  rules = {
    pathRequired: (v: string) => !!v || 'Path is required',
    posRequired: (v: string) => !!v || 'Position is required',
    required: (v: string) => !!v || 'Value required',
    number: (v: string) => /^\d*$/.test(v) || 'Position not a number',
    greater0: (v: string) => Number(v) > 0 || 'Position must be > 0',
    greatereq0: (v: string) => Number(v) >= 0 || 'Fraction must be >= 0',
    smaller1: (v: string) => Number(v) <= 1 || 'Fraction must be <= 1',
    // TODO: this may not work
    tooLarge: (v: string) => Number(v) <= this.currentMaxBin * this.selectedBinWidth || 'Position too large'
  }

  get selectedBinWidth () {
    return this.$store.state.chunkStore.binWidth
  }

  set selectedBinWidth (value) {
    this.lastBinWidth = this.$store.state.chunkStore.binWidth
    this.$store.commit('chunkStore/setBinWidth', value)
  }

  get currentMaxBin (): number {
    return this.$store.state.chunkStore.currentMaxBin
  }

  get sortOrderIcon () {
    if (this.sortOrder === 'asc') {
      return 'mdi-sort-alphabetical-ascending'
    } else {
      return 'mdi-sort-alphabetical-descending'
    }
  }

  get binWidths () {
    return LocalFileProvider.getAvailableBinWidths()
  }

  get dataSets () {
    return LocalFileProvider.getAvailableDatasets()
  }

  get paths () {
    return [...this.$store.state.indexStore.pathMap.keys()]
  }

  get genes () {
    return Object.keys(this.$store.state.metaStore.geneInfo)
  }

  get sortOptions () {
    return this.$store.getters['metaStore/getAvailableSortOptions']
    // return LocalFileProvider.getAvailableSortOptions()
  }

  get hintText () {
    let start = (this.sliderPosition[0] - 1) * this.$store.state.chunkStore.binWidth
    if (start === 0) start = 1
    const end = this.sliderPosition[1] * this.$store.state.chunkStore.binWidth
    return 'Position: ' +
      GeneralUtils.numberWithCommas(start) + ' - ' + GeneralUtils.numberWithCommas(end)
  }

  get sortOrder () {
    return this.$store.state.metaStore.selectedSortOrder
  }

  set sortOrder (value) {
    this.$store.commit('metaStore/setSelectedSortOrder', value)
  }

  get selectedDataset () {
    return this.$store.state.chunkStore.selectedDataset
  }

  set selectedDataset (value) {
    this.$store.commit('chunkStore/setDataset', value)
  }

  get selectedSortOption () {
    return this.$store.state.metaStore.selectedSortOption
  }

  set selectedSortOption (value) {
    this.$store.commit('metaStore/setSelectedSortOption', value)
  }

  get covFraction () {
    return this.$store.state.metaStore.covFraction
  }

  set covFraction (value) {
    this.$store.commit('metaStore/setLastCovFraction', this.$store.state.metaStore.covFraction)
    this.$store.commit('metaStore/setCovFraction', value)
  }

  get selectedPath () {
    return this.$store.state.metaStore.selectedPath
  }

  set selectedPath (value) {
    this.$store.commit('metaStore/setSelectedPath', value)
  }

  get selectedPathPos () {
    return this.$store.state.metaStore.selectedPathPosition
  }

  set selectedPathPos (value) {
    this.$store.commit('metaStore/setSelectedPathPosition', value)
  }

  get selectedPanPos () {
    return this.$store.state.metaStore.selectedPanPosition
  }

  set selectedPanPos (value) {
    this.$store.commit('metaStore/setSelectedPanPosition', value)
  }

  get selectedGene () {
    return this.$store.state.metaStore.selectedGene
  }

  set selectedGene (value) {
    this.$store.commit('metaStore/setSelectedGene', value)
  }

  get sliderPosition () {
    return this.$store.state.uiStore.sliderPosition
  }

  get drawLinks () {
    return this.$store.state.metaStore.drawLinks
  }

  set drawLinks (value) {
    this.leftmostBin = Graph.getBinAtViewportLeft()
    this.$store.commit('metaStore/setDrawLinks', value)
  }

  get drawInversions () {
    return this.$store.state.metaStore.drawInversions
  }

  set drawInversions (value) {
    this.$store.commit('metaStore/setDrawInversions', value)
  }

  get drawDuplications () {
    return this.$store.state.metaStore.drawDuplications
  }

  set drawDuplications (value) {
    this.$store.commit('metaStore/setDrawDuplications', value)
  }

  get selectedLinkType () {
    return this.$store.state.metaStore.selectedLinkType
  }

  set selectedLinkType (value) {
    this.$store.commit('metaStore/setSelectedLinkType', value)
  }

  get selectedMetadataToColor () {
    return this.$store.state.metaStore.selectedMetadataToColor
  }

  set selectedMetadataToColor (value) {
    this.$store.commit('metaStore/setSelectedMetadataToColor', value)
  }

  get metadataCategories () {
    return this.$store.state.metaStore.metaDataCategories
  }

  get metadataColors () {
    return this.$store.state.metaStore.metaDataColors
  }

  get maxSlider () {
    return this.$store.state.chunkStore.currentMaxBin
  }

  get rightClickData () {
    return this.$store.state.metaStore.rightClickData
  }

  get leftClickData () {
    return this.$store.state.metaStore.leftClickData
  }

  get drawCellMargin () {
    return this.$store.state.metaStore.drawCellMargin
  }

  set drawCellMargin (value) {
    this.leftmostBin = Graph.getBinAtViewportLeft()
    this.$store.commit('metaStore/setDrawCellMargin', value)
  }

  get denseView () {
    return this.$store.state.metaStore.denseView
  }

  set denseView (value) {
    this.leftmostBin = Graph.getBinAtViewportLeft()
    this.$store.commit('metaStore/setDenseView', value)
  }

  get jumpPathPosForm (): VForm {
    return this.$refs.jumpPathPosForm as VForm
  }

  get jumpPanPosForm (): VForm {
    return this.$refs.jumpPanPosForm as VForm
  }

  get textNrPaths () {
    return this.$store.state.metaStore.textNrPaths
  }

  set textNrPaths (value) {
    this.$store.commit('metaStore/setTextNrPaths', value)
  }

  get nrChunksToLoad () {
    return this.$store.state.chunkStore.nrChunksToLoad
  }

  set nrChunksToLoad (value) {
    this.$store.commit('chunkStore/setNrChunksToLoad', value)
  }

  get scale () {
    return this.$store.state.graphStore.scale
  }

  set scale (value) {
    this.$store.commit('graphStore/setScale', value)
  }

  get disabledGraphTracks () {
    return this.$store.state.uiStore.disabledGraphTracks
  }

  get disabledReadTracks () {
    return this.$store.state.uiStore.disabledReadTracks
  }

  // -------------------
  // Watchers
  // -------------------

  @Watch('selectedSortOption')
  onSelectedSortOptionChanged (value: string) {
    this.onSortChange()
  }

  @Watch('sortOrder')
  onSelectedSortOrderChanged (value: string) {
    this.onSortChange()
  }

  // Property mutated in TrackMenu.vue when the user disable a graphTrack
  @Watch('disabledGraphTracks')
  onDisabledGraphTracksChanged () {
    this.redrawKeepPos()
  }

  // Property mutated in TrackMenu.vue when the user disable a readTrack
  @Watch('disabledReadTracks')
  onDisabledReadsChanged () {
    this.redrawKeepPos()
  }

  // Property mutated in Graph (right click)
  @Watch('rightClickData')
  onRightClickData (value: { show: boolean, rawCoords: PIXI.Point, x: number, y: number, isLink: boolean }) {
    if (value.rawCoords.x < this.$store.state.metaStore.neededLeftMargin) {
      value.show = false
    } else if (value.show) {
      this.$store.commit('graphStore/setIsHighlightMenuOpened', true)
      Graph.removeHighlight()
      Graph.highlight(Graph.getColumnForCoordinate(value.rawCoords.x))
    }
  }

  // Property mutated in Graph (left click)
  @Watch('leftClickData')
  onLeftClickData (value: { rawCoords: PIXI.Point, cellHasInfo: boolean, cellHasGene: boolean }) {
    // if (value.cellHasInfo) {
    //   // Shift + left click = future implementation of region selection
    //   if (this.isShiftPressed) {
    //   } else {
    //   }
    // }
    if (value.cellHasGene) {
      this.showGeneMenu(value.rawCoords)
    } else {
      this.hideGeneMenu()
    }
    this.$store.commit('graphStore/setIsHighlightMenuOpened', false)
    Graph.removeHighlight()
  }

  // -------------------

  numFormatter (num: number) {
    return GeneralUtils.numFormatter(num)
  }

  jumpPathPos () {
    if (this.jumpPathPosForm.validate()) {
      PanPosService.getBinForPosition(this.selectedDataset, this.selectedPath, this.selectedPathPos).then((result) => {
        const position = Math.floor(parseInt(result.data.pan_pos) / parseInt(this.selectedBinWidth))
        if (position !== 0) {
          this.draw(position, true)
        }
      }).catch((error) => {
        if (error.response) {
          console.log(error.response.data)
        }
      })
    }
  }

  onSliderPositionChange () {
    this.draw(this.sliderPosition[0])
  }

  jumpPanPos () {
    if (this.jumpPanPosForm.validate() &&
      this.selectedPanPos > 0 &&
      this.selectedPanPos <= (this.$store.state.chunkStore.currentMaxBin * this.$store.state.chunkStore.binWidth)) {
      const bin = Number(this.selectedPanPos) / this.$store.state.chunkStore.binWidth
      this.draw(Math.round(bin), true)
    }
  }

  jumpToGene () {
    if (this.selectedGene) {
      console.log('selected gene:', this.selectedGene, 'bin', this.$store.state.metaStore.geneInfo[this.selectedGene].bin)
      const bin = this.$store.state.metaStore.geneInfo[this.selectedGene].bin
      this.draw(bin, true)
    }
  }

  onBinWidthChange () {
    if (!store.state.graphStore.loading) {
      // GeneralUtils.log('onBinWidChange', 'last binwid' + this.lastBinWidth + 'new' + this.$store.state.chunkStore.binWidth)
      this.$store.commit('chunkStore/setCurrentMaxBin', LocalFileProvider.getZoomLevelObj(this.$store.state.chunkStore.binWidth).num_bins)

      const highlightedCol = this.$store.state.graphStore.selectedHighlight
      let bin = 1
      if (highlightedCol && this.lastBinWidth) {
        bin = (Graph.getBinInfoForColumn(highlightedCol).binNumber - 1) * this.lastBinWidth / this.selectedBinWidth
        console.log(highlightedCol, Graph.getBinInfoForColumn(highlightedCol).binNumber, bin)
      } else {
        if (this.lastBinWidth) {
          bin = Math.trunc(parseInt(this.sliderPosition[0]) * this.lastBinWidth / this.selectedBinWidth)
        }
      }

      if (bin < 1) bin = 1
      if (this.selectedBinWidth >= GraphConfig.denseViewCutoff) {
        this.denseView = true
      }
      if (this.lastBinWidth && this.lastBinWidth >= GraphConfig.denseViewCutoff && this.selectedBinWidth < GraphConfig.denseViewCutoff) {
        this.denseView = false
      }

      // load new set of genes (from InitMetadataFromFile):
      LocalFileProvider.loadGeneFile(this.$store.state.chunkStore.path + '/bin' + this.$store.state.chunkStore.binWidth, GraphConfig.geneFileName)

      Graph.cleanupOnZoom()
      this.draw(bin, highlightedCol)
    }
  }

  onSortChange () {
    if (!this.$store.state.graphStore.loading) {
      SortingUtils.sortTracks()
      this.redrawKeepPos()
    }
  }

  onSortOrderChange () {
    if (this.sortOrder === 'asc') {
      this.sortOrder = 'desc'
    } else {
      this.sortOrder = 'asc'
    }

    if (!this.$store.state.graphStore.loading) {
      SortingUtils.sortTracks()
      this.redrawKeepPos()
    }
  }

  loadDataset (drawFromSliderPosition?: boolean, callback?: () => void) {
    if (!this.$store.state.graphStore.loading) {
      // Enable slider when the first dataset starts loading
      if (this.sliderDisabled === true) this.sliderDisabled = false
      // Reset sorting on dataset change
      SortingUtils.resetSort()
      // Reset binWidth
      this.selectedBinWidth = null
      // Reset paths to draw
      Graph.cleanupOnDatasetChange()
      Graph.removeHighlight()

      LocalFileProvider.loadGeneFile(this.$store.state.chunkStore.path + '/bin' + this.$store.state.chunkStore.binWidth, GraphConfig.geneFileName)

      if (drawFromSliderPosition) {
        this.draw(this.sliderPosition[0]).then(() => {
          if (callback) callback()
          this.$store.commit('uiStore/setSnapshotMenuEnabled', true)
        })
      } else {
        this.draw().then(() => {
          if (callback) callback()
          this.$store.commit('uiStore/setSnapshotMenuEnabled', true)
        })
      }
    }
  }

  onDatasetChange () {
    this.loadDataset()
  }

  onColumnSort () {
    if (this.rightClickData.rawCoords) {
      this.$store.commit('metaStore/setSelectedSortOption', 'column')

      store.commit('graphStore/setSelectedHighlight', Graph.getColumnForCoordinate(this.rightClickData.rawCoords.x))

      // getBinInfoForRawX with option sorting=true fills the metaStore.sortingTable Record accordingly
      Graph.getBinInfoForRawX(this.rightClickData.rawCoords.x, true)
      // metaStore.sortingTable is then used to sort the metaStore.pathsToDraw in the function below:
      SortingUtils.sortTracks()

      this.redrawKeepPos()
    }
  }

  onZoomIn () {
    this.sliderDisabled = true
    if (this.rightClickData.rawCoords) {
      const binInfo = Graph.getBinInfoForRawX(this.rightClickData.rawCoords.x)
      if (binInfo.type === 'bin') {
        const zoomLevelBefore = this.$store.state.chunkStore.binWidth

        // set new zoom level to next lower level:
        const zoomLevelAfter = LocalFileProvider.getLowerZoomLevel(zoomLevelBefore)
        if (zoomLevelAfter) {
          this.selectedBinWidth = zoomLevelAfter
          this.$store.commit('chunkStore/setCurrentMaxBin', LocalFileProvider.getZoomLevelObj(zoomLevelAfter).num_bins)

          // convert bin nr of old binWidth into bin nr of new binWidth
          const binNumberAfter = Math.round((binInfo.binNumber - 1) * zoomLevelBefore / zoomLevelAfter)

          // console.log(binInfo.binNumber, ' binwidth ', zoomLevelBefore, ' after ', zoomLevelAfter, ' jump to bin nr: ', binNumberAfter)

          if (zoomLevelAfter >= GraphConfig.denseViewCutoff) {
            this.denseView = true
          } else {
            this.denseView = false
          }

          if (!this.$store.state.graphStore.loading) {
            Graph.cleanupOnZoom()
            this.draw(binNumberAfter, true)
          }
        }
      }
    }
  }

  onZoomOut () {
    this.sliderDisabled = true
    if (this.rightClickData.rawCoords) {
      const binInfo = Graph.getBinInfoForRawX(this.rightClickData.rawCoords.x)
      if (binInfo.type === 'bin') {
        const zoomLevelBefore = this.$store.state.chunkStore.binWidth

        // set new zoom level to next lower level:
        const zoomLevelAfter = LocalFileProvider.getHigherZoomLevel(zoomLevelBefore)
        if (zoomLevelAfter) {
          this.selectedBinWidth = zoomLevelAfter
          this.$store.commit('chunkStore/setCurrentMaxBin', LocalFileProvider.getZoomLevelObj(zoomLevelAfter).num_bins)

          // convert bin nr of old binWidth into bin nr of new binWidth
          const binNumberAfter = Math.round(binInfo.binNumber * zoomLevelBefore / zoomLevelAfter)

          // console.log(binInfo.binNumber, ' binwidth ', zoomLevelBefore, ' after ', zoomLevelAfter, ' jump to bin nr: ', binNumberAfter)

          if (zoomLevelAfter >= GraphConfig.denseViewCutoff) {
            this.denseView = true
          } else {
            this.denseView = false
          }

          if (!this.$store.state.graphStore.loading) {
            Graph.cleanupOnZoom()
            this.draw(binNumberAfter, true)
          }
        }
      }
    }
  }

  // Called from right-click menu button
  followLink (coords: PIXI.Point) {
    Graph.followLink(coords.x, coords.y)
  }

  showTrackMenu () {
    this.$store.commit('uiStore/setSidebarState', { enabled: true, target: 'TrackMenu' })
  }

  // Called from right-click menu button or directly from left click (watcher)
  showGeneMenu (coords: PIXI.Point) {
    this.$store.dispatch('uiStore/setSidebarStateAsync', { enabled: true, target: 'GeneMenu' }).then(() => {
      this.$store.commit('uiStore/setGeneCoords', coords)
    }).catch((error) => {
      console.error(error)
    })
  }

  hideGeneMenu () {
    this.$store.commit('uiStore/setSidebarState', { enabled: false, target: null })
  }

  switchCellMargin () {
    this.redraw(this.leftmostBin, this.$store.state.graphStore.selectedHighlight !== null)
  }

  switchDrawLinks () {
    this.redraw(this.leftmostBin, this.$store.state.graphStore.selectedHighlight !== null)
  }

  switchDenseView () {
    this.redraw(this.leftmostBin, this.$store.state.graphStore.selectedHighlight !== null)
  }

  filterPathsbyCov () {
    if (this.selectedDataset) {
      Graph.filterPathsByCov().then(() =>
        this.redrawKeepPos())
    }
  }

  redrawKeepPos () {
    let startbin = Graph.getBinAtViewportLeft()
    if (this.$store.state.graphStore.selectedHighlight !== null) {
      startbin = Graph.getBinInfoForColumn(this.$store.state.graphStore.selectedHighlight).binNumber
    }
    this.redraw(startbin, this.$store.state.graphStore.selectedHighlight !== null)
  }

  redraw (startBin = 1, highlight = false) {
    return new Promise<void>((resolve, reject) => {
      this.menuOpen = false
      this.jumpMenuOpen = false
      if (!this.$store.state.graphStore.loading) {
        TooltipUtils.hide()
        Graph.drawCachedChunks(startBin, highlight)
      }
      resolve()
    })
  }

  draw (startBin = 1, highlight = false) {
    return new Promise<void>((resolve, reject) => {
      this.menuOpen = false
      this.jumpMenuOpen = false
      if (!store.state.graphStore.loading) {
        store.commit('graphStore/setLoading', true)
        TooltipUtils.hide()

        if (startBin === 0) {
          // Reset left margin if not moving on slider
          this.$store.commit('metaStore/setNeededLeftMargin', 0)
          this.sliderPosition[0] = 1
        }

        Graph.loadAndDrawChunks(startBin, highlight, highlight).then(() => {
          store.commit('graphStore/setLoading', false)
          this.sliderDisabled = false
        })
      }
      resolve()
    })
  }

  updateViewFromSnapshot (): void {
    if (this.$query.view === undefined) return
    const snapshot = JSON.parse(Buffer.from(this.$query.view as string, 'base64').toString('utf-8'))
    if (snapshot.selectedDataset) {
      this.selectedDataset = snapshot.selectedDataset
      if (snapshot.sliderPosition) {
        this.sliderPosition[0] = parseFloat(snapshot.sliderPosition as string)
        if (snapshot.covFraction) this.covFraction = parseFloat(snapshot.covFraction as string)
        if (snapshot.drawInversions) this.drawInversions = GeneralUtils.stringToBoolean(snapshot.drawInversions)
        if (snapshot.drawDuplications) this.drawDuplications = GeneralUtils.stringToBoolean(snapshot.drawDuplications)
        if (snapshot.drawCellMargin) this.drawCellMargin = GeneralUtils.stringToBoolean(snapshot.drawCellMargin)
        if (snapshot.scale) this.scale = parseFloat(snapshot.scale as string)
        // We wait for draw to complete
        this.loadDataset(true, () => {
          if (snapshot.pathsToDraw) this.$store.commit('metaStore/setPathsToDraw', GeneralUtils.stringToMap(snapshot.pathsToDraw))
          if (snapshot.selectedBinWidth) this.selectedBinWidth = parseInt(snapshot.selectedBinWidth as string)
          if (snapshot.selectedSortOption) this.selectedSortOption = snapshot.selectedSortOption
          if (snapshot.selectedSortOrder) this.sortOrder = snapshot.selectedSortOrder
          if (snapshot.selectedMetadataToColor) this.selectedMetadataToColor = snapshot.selectedMetadataToColor
          if (snapshot.drawLinks) this.drawLinks = GeneralUtils.stringToBoolean(snapshot.drawLinks)
          if (snapshot.denseView) this.denseView = GeneralUtils.stringToBoolean(snapshot.denseView)
        })
      }
    }
  }

  mounted (): void {
    // Init Graph
    Graph.init()

    // Initialize Tooltip
    TooltipUtils.initGlobalClickableTooltip()

    // Redraw upon window resize
    document.body.onresize = () => {
      this.redrawKeepPos()
    }

    // We check if the user has selected a dataset via the Dashboard
    // else, we try to update the current view from a 'snapshot' ($query) if available
    this.$router.onReady(() => {
      if (this.$router.currentRoute.params.dataset !== undefined) {
        this.loadDataset()
      } else {
        this.updateViewFromSnapshot()
      }
    })

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Shift') {
        this.isShiftPressed = true
      }
    })

    document.addEventListener('keyup', (e) => {
      if (e.key === 'Shift') {
        this.isShiftPressed = false
      }
    })
  }
}
