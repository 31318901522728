import { GraphStoreState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

const namespaced = true

const state: GraphStoreState = {
  isHightlighted: false,
  selectedHighlight: null,
  loading: false,
  isHighlightMenuOpened: false,
  scale: 1,
  placeholderVisible: false
}

const mutations: MutationTree<GraphStoreState> = {
  setIsHighlighted (state, payload) {
    state.isHightlighted = payload
  },

  setSelectedHighlight (state, payload) {
    state.selectedHighlight = payload
  },

  setLoading (state, payload) {
    state.loading = payload
  },

  setIsHighlightMenuOpened (state, payload) {
    state.isHighlightMenuOpened = payload
  },

  setScale (state, payload) {
    state.scale = payload
  },

  setPlaceholderVisible (state, payload) {
    state.placeholderVisible = payload
  }
}

const actions: ActionTree<GraphStoreState, RootState> = {}
const getters: GetterTree<GraphStoreState, RootState> = {}

export const GraphStore: Module<GraphStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
