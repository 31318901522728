
import { Component, Vue } from 'vue-property-decorator'
import { SidebarState } from '@/types/Types'
import TrackMenu from '@/components/TrackMenu.vue'
import GeneMenu from '@/components/GeneMenu.vue'

@Component({
  components: {
    TrackMenu,
    GeneMenu
  }
})
export default class Sidebar extends Vue {
  get sidebarState () {
    return this.$store.state.uiStore.sidebarState
  }

  set sidebarState (state: SidebarState) {
    this.$store.commit('uiStore/setSidebarState', state)
  }

  close () {
    this.sidebarState = { enabled: false, target: null }
  }
}
