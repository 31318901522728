
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Legend extends Vue {
  legend: HTMLElement | null = null
  dragging = false
  legendPositionX = 0.0
  legendPositionY = 0.0
  legendOffsetX = 0.0
  legendOffsetY = 0.0
  limitX = 0.0
  limitY = 0.0
  margin = 20

  get visible () {
    return this.$store.state.uiStore.legendVisible
  }

  set visible (val: boolean) {
    this.$store.commit('uiStore/setLegendVisible', val)
  }

  mounted () {
    this.legend = this.$refs.legend as HTMLElement
    const rect = this.legend.getBoundingClientRect()
    this.limitX = rect.width + this.margin
    this.limitY = rect.height + this.margin
  }

  close (e: MouseEvent) {
    this.visible = false
  }

  dragStart (e: MouseEvent) {
    if (e.target === this.$refs.legend) {
      window.addEventListener('mousemove', this.doDrag)
      this.dragging = true
      this.legendOffsetX = e.offsetX
      this.legendOffsetY = e.offsetY
    }
  }

  dragEnd (e: MouseEvent) {
    if (e.target === this.$refs.legend) {
      window.removeEventListener('mousemove', this.doDrag)
      this.dragging = false
    }
  }

  doDrag (e: MouseEvent) {
    this.legendPositionX = e.clientX - this.legendOffsetX
    this.legendPositionY = e.clientY - this.legendOffsetY

    if (this.dragging && this.legendPositionX >= this.margin && this.legendPositionX + this.limitX <= window.innerWidth) {
      if (this.legend !== null) {
        this.legend.style.left = this.legendPositionX + 'px'
      }
    }
    if (this.dragging && this.legendPositionY >= this.margin && this.legendPositionY + this.limitY <= window.innerHeight) {
      if (this.legend !== null) {
        this.legend.style.top = this.legendPositionY + 'px'
      }
    }
  }
}
