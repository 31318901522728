
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Buffer } from 'buffer'
import GeneralUtils from '@/utils/GeneralUtils'
import Chip from './Chip.vue'

@Component({
  components: {
    Chip
  }
})
export default class SnapshotMenu extends Vue {
  chipVisible = false
  queryItem = { key: '', value: '' }
  sharingQuery = {
    sliderPosition: '',
    selectedDataset: '',
    selectedBinWidth: '',
    selectedSortOption: '',
    selectedMetadataToColor: '',
    covFraction: '',
    drawInversions: '',
    drawDuplications: '',
    drawLinks: '',
    drawCellMargin: '',
    denseView: '',
    scale: '',
    pathsToDraw: ''
  }

  // -------------------
  // Getters
  // -------------------
  get enabled () {
    return this.$store.state.uiStore.snapshotMenuEnabled
  }

  get sliderPosition () {
    return this.$store.state.uiStore.sliderPosition
  }

  get selectedDataset () {
    // Hack: we update the query here because the watcher doesn't get called when the dataset is first selected from the Dashboard
    // (probably because the watcher is not registered at this point)
    this.updateQuery('selectedDataset', this.$store.state.chunkStore.selectedDataset)
    return this.$store.state.chunkStore.selectedDataset
  }

  get selectedBinWidth () {
    return this.$store.state.chunkStore.binWidth
  }

  get selectedSortOption () {
    return this.$store.state.metaStore.selectedSortOption
  }

  get sortOrder () {
    return this.$store.state.metaStore.selectedSortOrder
  }

  get covFraction () {
    return this.$store.state.metaStore.covFraction
  }

  get drawInversions () {
    return this.$store.state.metaStore.drawInversions
  }

  get drawDuplications () {
    return this.$store.state.metaStore.drawDuplications
  }

  get drawCellMargin () {
    return this.$store.state.metaStore.drawCellMargin
  }

  get selectedMetadataToColor () {
    return this.$store.state.metaStore.selectedMetadataToColor
  }

  get drawLinks () {
    return this.$store.state.metaStore.drawLinks
  }

  get denseView () {
    return this.$store.state.metaStore.denseView
  }

  get scale () {
    return this.$store.state.graphStore.scale
  }

  get pathsToDraw () {
    return this.$store.state.metaStore.pathsToDraw
  }

  // -------------------
  // Watchers
  // -------------------
  @Watch('sliderPosition')
  onSliderPositionChanged (value: number) {
    this.updateQuery('sliderPosition', String(value))
  }

  @Watch('selectedDataset')
  onSelectedDatasetChanged (value: string) {
    // Hack: we update the query here because the watcher doesn't get called when the dataset is first selected from the Dashboard
    // (probably because the watcher is not registered at this point)
    // this.updateQuery('selectedDataset', value)
  }

  @Watch('selectedBinWidth')
  onSelectedBinWidthChanged (value: number) {
    this.updateQuery('selectedBinWidth', String(value))
  }

  @Watch('selectedMetadataToColor')
  onSelectedMetadataToColorChanged (value: string) {
    this.updateQuery('selectedMetadataToColor', value)
  }

  @Watch('selectedSortOption')
  onSelectedSortOptionChanged (value: string) {
    this.updateQuery('selectedSortOption', value)
  }

  @Watch('sortOrder')
  onSelectedSortOrderChanged (value: string) {
    this.updateQuery('selectedSortOrder', value)
  }

  @Watch('covFraction')
  onCovFractionChanged (value: number) {
    this.updateQuery('covFraction', String(value))
  }

  @Watch('drawLinks')
  onDrawLinksChanged (value: boolean) {
    this.updateQuery('drawLinks', String(value))
  }

  @Watch('drawInversions')
  onDrawInversionsChanged (value: boolean) {
    this.updateQuery('drawInversions', String(value))
  }

  @Watch('drawDuplications')
  onDrawDuplicationsChanged (value: boolean) {
    this.updateQuery('drawDuplications', String(value))
  }

  @Watch('drawCellMargin')
  onDrawCellMarginChanged (value: boolean) {
    this.updateQuery('drawCellMargin', String(value))
  }

  @Watch('denseView')
  onDenseViewChanged (value: boolean) {
    this.updateQuery('denseView', String(value))
  }

  @Watch('scale')
  onScaleChanged (value: number) {
    this.updateQuery('scale', String(value))
  }

  @Watch('pathsToDraw')
  onPathsToDrawChanged (value: Map<string, string>) {
    this.updateQuery('pathsToDraw', GeneralUtils.mapToString(value))
  }

  // -------------------

  shareSnapshot () {
    this.updatePath()
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        this.chipVisible = !this.chipVisible
      })
      .catch((error) => {
        console.error('Failed to copy snapshot to clipboard: ', error)
      })
  }

  updatePath (): void {
    const query = { view: this.encodeQuery() }
    if (this.$route.query !== query) {
      this.$router.push({ path: '/graph', query: query }).catch(err => {
      // Ignore this error
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // Print any other error
          console.log(err)
        }
      })
    }
  }

  updateQuery (key: string, value: string): void {
    if (value) this.sharingQuery[key as keyof typeof this.sharingQuery] = value
  }

  encodeQuery (): string {
    const sq = this.sharingQuery
    const preppedQuery: Array<typeof this.queryItem> = []
    Object.keys(sq).forEach((key) => {
      if (sq[key as keyof typeof sq].length !== 0) {
        preppedQuery.push({ key: key, value: sq[key as keyof typeof sq] })
      }
    })
    const query = preppedQuery.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {})
    const encodedQuery = Buffer.from(JSON.stringify(query)).toString('base64')
    return encodedQuery
  }
}
