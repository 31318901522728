const GeneralUtils = {
  // TODO: correct type for this?
  isRightClick (event: any) {
    return event.event.data.button === 2
  },

  numberWithCommas (n: number) {
    // from https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },

  numFormatter (num: number) {
    // adapted from https://www.codegrepper.com/code-examples/javascript/javascript+convert+number+from+thousands+to+k+and+millions+to+m
    if (num >= 1000 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'k' // convert to k for number from > 1000 < 1 million
    } else if (num >= 1000000 && num < 1000000000) {
      return (num / 1000000).toFixed(1) + 'M' // convert to M for number from > 1 million
    } else if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1) + 'G' // convert to G for number from > 1 trillion
    } else if (num < 1000) {
      return num // if value < 1000, nothing to do
    }
  },

  numToHex (num: number) {
    const hexString = num.toString(16)
    return hexString === '0' ? '000000' : hexString
  },

  log (prefix: string, str: string) {
    console.log('[', prefix, this.getTime(), ']', str)
  },

  getTime () {
    const time = new Date()
    return time.getMilliseconds() + ':' + time.getSeconds() + ':' + time.getMilliseconds()
  },

  stringToBoolean (value: string): boolean {
    return value === 'true'
  },

  mapToString (map: Map<string, string>) {
    return Array.from(map.entries()).map(([key, value]) => `${key}:${value}`).join(',')
  },

  stringToMap (str: string) {
    const map = new Map<string, string>()
    if (str) {
      str.split(',').forEach((pair) => {
        const [key, value] = pair.split(':')
        map.set(key, value)
      })
    }
    return map
  }
}

export default GeneralUtils
