
import { Vue, Component, Watch } from 'vue-property-decorator'

// TrackMenu allows the user to select which tracks (paths or reads) to display in Pantoview
@Component
export default class TrackMenu extends Vue {
  allGraphTracksSelected = true
  allReadTracksSelected = true

  panel = [0]

  // ---------------------------
  // GraphTracks
  // ---------------------------

  get graphTracks () {
    return this.$store.state.chunkStore.graphTracks
  }

  // A new string (graph name) is pushed/removed every time the user disables/enables a graph
  get disabledGraphTracks () {
    return this.$store.state.uiStore.disabledGraphTracks
  }

  set disabledGraphTracks (graphTracks: string[]) {
    this.$store.commit('uiStore/setDisabledGraphTracks', graphTracks)
  }

  @Watch('graphTracks')
  onGraphTracksChanged (val: any) {
    if (val.entries().next().value !== undefined) {
      this.allGraphTracksSelected = this.checkAllTracksSelected('graph')
    }
  }

  // ---------------------------
  // ReadTracks
  // ---------------------------

  get readTracks () {
    return this.$store.state.chunkStore.readTracks[this.$store.state.chunkStore.currentChunk]
  }

  get disabledReadTracks () {
    return this.$store.state.uiStore.disabledReadTracks
  }

  set disabledReadTracks (readTracks: string[]) {
    this.$store.commit('uiStore/setDisabledReadTracks', readTracks)
  }

  @Watch('readTracks')
  onReadTracksChanged (val: any) {
    if (val.entries().next().value !== undefined) {
      this.allReadTracksSelected = this.checkAllTracksSelected('read')
    }
  }

  // ---------------------------

  getDisabledTracks (type: string) {
    return type === 'graph' ? this.disabledGraphTracks : this.disabledReadTracks
  }

  getAllTracksSelected (type: string) {
    return type === 'graph' ? this.allGraphTracksSelected : this.allReadTracksSelected
  }

  setAllTracksSelected (type: string, value: boolean) {
    if (type === 'graph') {
      this.allGraphTracksSelected = value
    } else {
      this.allReadTracksSelected = value
    }
  }

  getTracks (type: string) {
    // If readTracks are found in the dataset, create a map with the readset_name as key
    // otherwise, return the graphTracks, which are always present
    if (this.readTracks !== undefined) {
      const readTracks = new Map()
      for (const track of this.readTracks) {
        readTracks.set(track.readset_name, track)
      }
      return type === 'graph' ? this.graphTracks : readTracks
    } else {
      return this.graphTracks
    }
  }

  isTrackDisabled (type: string, name: string) {
    return !(this.getDisabledTracks(type).indexOf(name) > -1)
  }

  checkAllTracksSelected (type: string): boolean {
    const disabledTracks = this.getDisabledTracks(type)
    const currentTracks = this.getTracks(type)
    for (const [key, value] of currentTracks) {
      if (disabledTracks.indexOf(key) > -1) {
        return false
      }
    }
    return true
  }

  toggleAllTracks (type: string) {
    const disabledTracks = this.getDisabledTracks(type)
    const currentTracks = this.getTracks(type)
    for (const [key, value] of currentTracks) {
      const index = disabledTracks.indexOf(key)
      if (this.getAllTracksSelected(type)) {
        disabledTracks.splice(index, 1)
      } else {
        if (index === -1) {
          disabledTracks.push(key)
        }
      }
    }
  }

  toggleTrack (type: string, key: string) {
    const disabledTracks = this.getDisabledTracks(type)
    const index = disabledTracks.indexOf(key)
    if (index > -1) {
      disabledTracks.splice(index, 1)
    } else {
      disabledTracks.push(key)
    }
    this.setAllTracksSelected(type, this.checkAllTracksSelected(type))
  }
}
